import Vue from 'vue';
import TYPES from '@/types';
import {
  curpFormat,
  percentageFormat,
  phoneFormat,
  requiredRule,
} from '@/vue-app/utils/form-rules';

// APPLICATION
import { GetRelationshipsQueryService } from '@/modules/onboarding/catalogs/relationship/application/queries';

// DOMAIN
import { BeneficiaryEntity } from '@/modules/onboarding/beneficiary/domain/types';
import Inject from '@/modules/shared/domain/di/inject';

export class BeneficiariesFormViewModel {
  @Inject(TYPES.GET_RELATIONSHIPS_QUERY_SERVICE)
  readonly getRelationshipsQueryService!: GetRelationshipsQueryService;

  beneficiary!: BeneficiaryEntity;

  rules = {
    required: [requiredRule],
    curp: [curpFormat],
    percentage: [percentageFormat],
    phone_format: [requiredRule, phoneFormat],
  };

  form_index = 0;

  show_form = false;

  view!: Vue;

  get relationships() {
    return this.getRelationshipsQueryService.execute();
  }

  get percentage() {
    return this.beneficiary.percentage ? `${this.beneficiary.percentage}%` : '0%';
  }

  get full_name() {
    return `${this.beneficiary.name} ${this.beneficiary.lastname} ${this.beneficiary.second_lastname}`;
  }

  get beneficiary_text() {
    return this.beneficiary.name ? this.full_name : `Beneficiario ${this.form_index + 1}`;
  }

  get show_remove_btn() {
    return this.form_index > 0;
  }

  constructor(view: Vue) {
    this.view = view;
  }

  setBeneficiary = (beneficiary: BeneficiaryEntity) => {
    this.beneficiary = beneficiary;
  };

  setFormIndex = (index: number) => {
    this.form_index = index;
  };

  curpToUpperCase = () => {
    this.beneficiary.curp = this.beneficiary.curp.toUpperCase();
  };

  toggleFormCard = () => {
    this.show_form = !this.show_form;
  };
}
