





























































import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component({ name: 'AcceptedProofOfAddress' })
export default class AcceptedProofOfAddress extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;
}
