




















































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Card from '../../Card.vue';
import RfcSectionViewModel
  from '@/vue-app/view-models/components/on-boarding/personal-info/tax-information/rfc-section-view-model';
import PreviewDocument from '@/vue-app/components/documents/preview-document.vue';

@Component({
  name: 'RfcSection',
  components: {
    Card,
    PreviewDocument,
  },
})
export default class RfcSection extends Vue {
  rfc_section_view_model = Vue.observable(new RfcSectionViewModel());

  @Watch('rfc_section_view_model.file_is_being_uploaded')
  onFileIsBeingUploadedChange(file_is_being_uploaded: boolean) {
    if (file_is_being_uploaded) {
      this.$notify({
        group: 'loader',
        title: 'Estamos subiendo tu documento, terminaremos en unos segundos',
        duration: -1,
      });
    } else {
      this.$notify({
        group: 'loader',
        clean: true,
      });
    }
  }

  @Watch('rfc_section_view_model.person')
  onPersonChange() {
    this.rfc_section_view_model.setRfcData();
    this.rfc_section_view_model.setFielData();
  }

  @Watch('rfc_section_view_model.documents')
  onDocumentsChange() {
    this.rfc_section_view_model.setDocumentData();
  }

  @Watch('rfc_section_view_model.agreements')
  onAgreementsChange() {
    this.rfc_section_view_model.setAgreementsData();
  }

  @Watch('rfc_section_view_model.confirm_inputs')
  onConfirmInputsChange(confirm_inputs: boolean) {
    this.rfc_section_view_model.toggleConfirmRfcInformationAgreement(confirm_inputs);
  }

  created() {
    this.rfc_section_view_model.initialize();
  }
}
