import Vue from 'vue';

export default class RealResourceProviderViewModel {
  readonly view: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  handleConfirmRealResourceProvider = () => {
    this.view.$emit('confirmRealResourceProvider', true);
  }
}
