import { StepDto } from '@/modules/onboarding/status/domain/dtos';
import { SectionBase } from '@/modules/onboarding/status/domain/services/onboarding-step/step';

export default class BankInformation implements SectionBase {
  getStepAndSubStep(): StepDto {
    return {
      step: 6,
      substep: 1,
    };
  }
}
