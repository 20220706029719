import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import { StepEntity } from '../../domain/entities';
import { StateManager } from '../../domain/state/state-manager';
import TYPES from '../../types';

export default class GetOnboardingCurrentStepQuery extends Query<Promise<StepEntity>> {
  @Inject(TYPES.ONBOARDING_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(): Promise<StepEntity> {
    const current_step = this.state_manager.state.steps[0];

    return Promise.resolve(current_step);
  }
}
