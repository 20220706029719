import { v4 } from 'uuid';

import TYPES from '@/types';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import { GetSuburbsInZipCodeQuery } from '@/modules/onboarding/catalogs/suburb/application/queries';
import GetCountriesQueryService
  from '@/modules/onboarding/catalogs/country/application/queries/get-countries-query-service';

// Domain
import { CustomerAddressEntity }
  from '@/modules/onboarding/address/domain/entities/customer-address-entity';
import { SuburbInZipCodeEntity } from '@/modules/onboarding/catalogs/suburb/domain/entities';
import { CountryEntity }
  from '@/modules/onboarding/catalogs/country/domain/entities/country-entity';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/dependency_injection/inject';

export default class AddressFormViewModel {
  @Inject(TYPES.GET_SUBURBS_IN_ZIP_CODE_QUERY)
  readonly get_suburbs_in_zip_code_query!: GetSuburbsInZipCodeQuery;

  @Inject(TYPES.GET_COUNTRIES_QUERY_SERVICE)
  readonly get_countries_query!: GetCountriesQueryService;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  current_step: StepEntity = {
    id: '',
    current_step: '',
    payload: {},
  };

  rules = {
    required: [requiredRule],
  };

  inputs: CustomerAddressEntity = {
    customer_address_proof_of_address_id: null,
    customer_address_id: '',
    customer_address_default: false,
    address: {
      id: '',
      street: '',
      external_number: '',
      interior_number: '',
      zip_code: '',
      settlement: '',
      municipality: '',
      state: '',
      city: '',
      address_type: 'fiscal',
      country: {
        id: '',
        name: '',
      },
      created_at: new Date().toUTCString(),
      updated_at: new Date().toUTCString(),
    },
  };

  get suburbs_in_zip_code() {
    if (this.inputs.address.zip_code.length === 5) {
      const response = this.get_suburbs_in_zip_code_query.execute(this.inputs.address.zip_code);

      if (response.length) {
        this.inputs.address.municipality = response[0].municipality_name;
        this.inputs.address.state = response[0].state_name;
        this.inputs.address.city = response[0].city_name;
      }

      return response;
    }

    return [];
  }

  get countries() {
    return this.get_countries_query.execute();
  }

  fillCustomerAddressSuburbData = (suburb_in_zip_code?: SuburbInZipCodeEntity) => {
    if (suburb_in_zip_code) {
      this.inputs.address.municipality = suburb_in_zip_code.municipality_name;
      this.inputs.address.state = suburb_in_zip_code.state_name;
      this.inputs.address.city = suburb_in_zip_code.city_name;
    } else {
      this.inputs.address.municipality = '';
      this.inputs.address.state = '';
      this.inputs.address.settlement = '';
    }
  }

  setCustomerAddressCountry = (new_countries: Array<CountryEntity>) => {
    if (!this.inputs.address.country.id) {
      const default_country = new_countries.find(
        (country) => country.value === 'México',
      );

      if (default_country) {
        this.inputs.address.country = {
          id: default_country.id,
          name: default_country.value,
        };
      }
    }
  }

  initialize = async () => {
    this.current_step = await this.get_on_boarding_step_query.execute('tax_information');
    this.setCustomerAddressOrInitializeIt();
  }

  private setCustomerAddressOrInitializeIt() {
    const customer_address = this.current_step.payload.addresses.find(
      (item: CustomerAddressEntity) => item.address.address_type === 'fiscal',
    );

    if (customer_address) {
      this.inputs = customer_address;
      this.get_suburbs_in_zip_code_query.execute(this.inputs.address.zip_code);
    } else {
      const id = v4();
      this.inputs.customer_address_id = id;
      this.inputs.address.id = id;
    }
  }
}
