

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'NavigationMobile' })
export default class Navigation extends Vue {
  @Prop({ default: 1 })
  selected!: number;

  scroll = 0;

  items = [
    'Registro',
    'Verificación de Identidad',
    'Carga de Documentos',
    'Información Personal',
    'Información Financiera',
    'Información Bancaria',
    'Beneficiarios',
    'Confirmación',
  ];

  created() {
    window.addEventListener('scroll', () => {
      this.scroll = window.scrollY;
    }, { passive: true });
  }

  unmounted() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.removeEventListener('scroll', () => {});
  }
}

