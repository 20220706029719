import { v4 } from 'uuid';
import TYPES from '@/types';
import { fielFormat, requiredRule } from '@/vue-app/utils/form-rules';

// Infrastructure
import Functions from '@/modules/shared/infrastructure/utils/functions';

// Application
import UploadRfcFileCommand
  from '@/modules/onboarding/documents/application/commands/upload-rfc-file-command';
import { GetPersonQueryService } from '@/modules/onboarding/person/application/queries';
import SearchDocumentsQuery
  from '@/modules/onboarding/documents/application/queries/search-documents-query';
import SearchAgreementsQuery
  from '@/modules/agreements/application/queries/search-agreements-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import GetCustomerBase64DocumentQuery
  from '@/modules/onboarding/customer-document/application/queries/get-customer-base64-document-query';
import MimeTypeFromBase64
  from '@/modules/onboarding/file/application/services/mime-type-from-base64';
import Base64DataUriToFileConverter
  from '@/modules/onboarding/file/application/services/base64-data-uri-to-file-converter';

// Domain
import { DocumentEntity } from '@/modules/onboarding/documents/domain/entities/document-entity';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class RfcSectionViewModel {
  @Inject(TYPES.UTIL_FUNCTIONS)
  private readonly functions!: Functions;

  @Inject(TYPES.ON_BOARDING_UPLOAD_RFC_FILE_COMMAND)
  private readonly upload_rfc_file_command!: UploadRfcFileCommand;

  @Inject(TYPES.GET_PERSON_QUERY_SERVICE)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_DOCUMENTS_QUERY)
  private readonly search_documents_query!: SearchDocumentsQuery;

  @Inject(TYPES.SEARCH_AGREEMENTS_QUERY)
  private readonly search_agreements_query!: SearchAgreementsQuery;

  @Inject(TYPES.GET_CUSTOMER_BASE64_DOCUMENT_QUERY)
  readonly get_customer_base64_document_query!: GetCustomerBase64DocumentQuery;

  @Inject(TYPES.DATETIME_VALUE)
  private readonly datetime_value!: DatetimeValue;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly notifier!: MessageNotifier;

  readonly inputs_config = {
    confirm_inputs: [requiredRule],
    rfc_without_homoclave: [
      (value: string) => (value === '' || /[a-zA-Z]{4}[0-9]{6}/.test(value)) || 'RFC inválido',
    ],
    homoclave: [
      (value: string) => (value === '' || /^[a-zA-Z0-9]{3}$/.test(value)) || 'Homoclave inválida',
    ],
    file: {
      size_limit: 1000 * 1024 * 4,
    },
    fiel: [fielFormat],
  };

  inputs = {
    rfc_without_homoclave: '',
    homoclave: '',
    file: null as null | File,
    fiel: '',
  };

  customer_agreements = [
    {
      id: '',
      agreement_type_id: '',
      was_accepted: false,
      accepted_on: null as DatetimeValue | null,
      description: '',
    },
  ];

  confirm_inputs = false;

  is_loading = false;

  file_is_being_uploaded = false;

  show_preview = false;

  documents!: Array<DocumentEntity>;

  get rfc() {
    return `${this.inputs.rfc_without_homoclave}${this.inputs.homoclave}`;
  }

  get valid_inputs() {
    return (
      /^[a-zA-Z]{4}[0-9]{6}[a-zA-Z0-9]{3}$/.test(this.rfc)
      && (
        /^[\d]{20,25}$/.test(this.inputs.fiel)
        || this.inputs.fiel === ''
      )
    );
  }

  get confirm_inputs_disabled() {
    return !this.valid_inputs || this.is_loading || !this.inputs.file;
  }

  get person() {
    return this.get_person_query.execute();
  }

  get agreements() {
    return this.search_agreements_query.execute();
  }

  initialize = async () => {
    await this.getAllCustomerDocuments();
    this.get_person_query.execute();
    await this.search_documents_query.execute();
    this.search_agreements_query.execute();
    await this.setRfcData();
    await this.setFielData();
    await this.setDocumentData();
    await this.setAgreementsData();
  };

  getAllCustomerDocuments = async () => {
    try {
      this.documents = await this.search_documents_query.execute();
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al obtener los documentos del usuario');
    }
  };

  selectFile = async ({ target }: any) => {
    const selected_file = target.files[0];

    if (!selected_file) return;

    if (
      selected_file.size >= 1000
      && selected_file.size <= this.inputs_config.file.size_limit
    ) {
      this.inputs.file = selected_file;
      this.file_is_being_uploaded = true;

      try {
        const file_data = await this.functions.convert_file_to_base_64(selected_file);
        const create_file_dto = {
          id: v4(),
          name: selected_file.name,
          mime_type: selected_file.type,
          file_data: file_data as string,
        };

        await this.upload_rfc_file_command.execute(create_file_dto);

        this.show_preview = true;
      } catch {
        this.show_preview = false;
        this.inputs.file = null;
      } finally {
        this.file_is_being_uploaded = false;
      }
    } else {
      this.notifier.showErrorNotification(
        'Ocurrio un error subir la cédula de identificación fiscal.',
      );
    }
  }

  setRfcData = async () => {
    const current_step = await this.get_on_boarding_step_query.execute('tax_information');
    const rfc = current_step.payload.person.rfc || this.person.rfc;

    if (rfc.length > 10) {
      this.inputs.rfc_without_homoclave = rfc.slice(0, 10);
      this.inputs.homoclave = rfc.slice(10, 13);
    } else {
      this.inputs.rfc_without_homoclave = rfc;
    }
  }

  setFielData = async () => {
    const current_step = await this.get_on_boarding_step_query.execute('tax_information');
    this.inputs.fiel = current_step.payload.person.fiel;
  }

  downloadCustomerDocumentPreview = async (customer_document_id: string, file_name: string) => {
    this.is_loading = true;
    try {
      const base64 = await this.get_customer_base64_document_query.execute(customer_document_id);
      const mime_type = MimeTypeFromBase64.getFileMimeType(base64);
      const filePreview = Base64DataUriToFileConverter.convert(base64, mime_type, file_name);
      this.show_preview = true;
      return filePreview;
    } catch (e) {
      this.show_preview = false;
      this.notifier.showErrorNotification('Ocurrió un error al descargar la previsualización del documento');
      return null;
    } finally {
      this.is_loading = false;
    }
  };

  setDocumentData = async () => {
    if (!this.inputs.file) {
      const rfc_document = this.documents.find(
        (item) => item.document_type.name === 'rfc',
      );

      if (rfc_document) {
        const file_name = rfc_document.file.name;
        this.inputs.file = await this.downloadCustomerDocumentPreview(
          rfc_document.customer_document_id, file_name,
        );
      }
    }
  }

  setAgreementsData = async () => {
    const current_step = await this.get_on_boarding_step_query.execute('tax_information');

    if (current_step.payload.agreements.length) {
      this.customer_agreements = current_step.payload.agreements;
      this.confirm_inputs = this.customer_agreements[0].was_accepted;
    }

    if (!this.customer_agreements[0].id) {
      const confirm_rfc_information_agreement = this.agreements.find(
        (item) => item.name === 'confirm_rfc_information',
      );

      if (confirm_rfc_information_agreement) {
        this.customer_agreements[0].id = v4();
        this.customer_agreements[0].agreement_type_id = confirm_rfc_information_agreement.id;
        this.customer_agreements[0].description = confirm_rfc_information_agreement.description;
      }
    }
  }

  toggleConfirmRfcInformationAgreement = (was_accepted: boolean) => {
    this.customer_agreements[0].was_accepted = was_accepted;
    this.customer_agreements[0].accepted_on = this.datetime_value.create();
  }
}
