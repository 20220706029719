



































import { Component, Vue, PropSync } from 'vue-property-decorator';
import RealOwnerOfResourcesViewModel from '@/vue-app/view-models/components/on-boarding/financial-information/real-owner-of-resources-view-model';

@Component({ name: 'RealOwnerOfResources' })
export default class RealOwnerOfResources extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  private readonly real_owner_of_resources_view_model = Vue.observable(
    new RealOwnerOfResourcesViewModel(this),
  );

  accept = null;
}
