









































































































/* eslint-disable @typescript-eslint/ban-ts-ignore */

import { Component, Vue } from 'vue-property-decorator';
import Navigation from '@/vue-app/components/onboarding/Navigation.vue';
import NavigationMobile from '@/vue-app/components/onboarding/NavigationMobile.vue';
import NipPage from '@/vue-app/components/onboarding/register/NipPage.vue';
import PrepareDocumentsPage from '@/vue-app/components/onboarding/documents/PrepareDocumentsPage.vue';
import VerifyYourIdentityPage from '@/vue-app/components/onboarding/verify-identity/VerifyYourIdentityPage.vue';
import VerifyIdentityPage from '@/vue-app/components/onboarding/verify-identity/VerifyIdentityPage.vue';
import UploadDocumentsPage from '@/vue-app/components/onboarding/documents/UploadDocumentsPage.vue';
import PersonalInfoPage1 from '@/vue-app/components/onboarding/personal-info/PersonalInfoPage1.vue';
import PersonalInfoPage2 from '@/vue-app/components/onboarding/personal-info/PersonalInfoPage2.vue';
import FinancialInformationPage from '@/vue-app/components/onboarding/financial-information/FinancialInformationPage.vue';
import BeneficiariesPage from '@/vue-app/components/onboarding/beneficiaries/BeneficiariesPage.vue';
import BankInformationPage from '@/vue-app/components/onboarding/bank-information/BankInformationPage.vue';
import TermsAndConditionsPage from '@/vue-app/components/onboarding/TermsAndConditionsPage.vue';
import RegistrationStatus from '@/vue-app/components/onboarding/RegistrationStatus.vue';
import GeolocationPermissionModal
  from '@/vue-app/components/geolocation/GeolocationPermissionModal.vue';
import OnboardingSearchStepsQuery
  from '@/modules/onboarding/status/application/queries/onboarding-search-steps-query';
import GetOnboardingCurrentStepQuery
  from '@/modules/onboarding/status/application/queries/get-onboarding-current-step-query';
import OnboardingStep
  from '@/modules/onboarding/status/application/services/onboarding-step';
import Inject from '@/modules/shared/domain/di/inject';
import TYPES from '@/types';
import GetOnboardingStepName
  from '@/modules/onboarding/status/application/services/get-onboarding-step-name';
import GetOnboardingStepQuery
  from '@/modules/onboarding/status/application/queries/get-onboarding-step-query';
import GetInternetStatusQuery
  from '@/modules/internet-status/application/queries/get-internet-status-query';

const components = {
  Navigation,
  NavigationMobile,
  NipPage,
  PrepareDocumentsPage,
  VerifyYourIdentityPage,
  VerifyIdentityPage,
  UploadDocumentsPage,
  PersonalInfoPage1,
  PersonalInfoPage2,
  FinancialInformationPage,
  BankInformationPage,
  BeneficiariesPage,
  TermsAndConditionsPage,
  RegistrationStatus,
  GeolocationPermissionModal,
  HiringCompleted: () => import('@/vue-app/components/onboarding/HiringCompleted.vue'),
};

@Component({
  name: 'Onboarding',
  components,
  beforeRouteEnter(to, from, next) {
    const onboardingSearchStepsQuery = new OnboardingSearchStepsQuery();
    onboardingSearchStepsQuery.execute()
      .then(() => {
        const getOnboardingCurrentStepQuery = new GetOnboardingCurrentStepQuery();

        getOnboardingCurrentStepQuery.execute()
          .then((current_step) => {
            const onboardingStep = new OnboardingStep();
            const set_step_with_param = to.query.pending_deposit === 'true';

            const { step, substep } = onboardingStep.getStepAndSubStep(
              set_step_with_param
                ? {
                  id: 'temporary',
                  current_step: 'validation_process',
                  payload: {
                    is_valid: true,
                    rescheduled: false,
                  },
                }
                : current_step,
            );

            next((vm) => {
              // eslint-disable-next-line no-param-reassign
              vm.$data.current_step = step;
              // eslint-disable-next-line no-param-reassign
              vm.$data.current_substep = substep;
            });
          })
          .catch(() => {
            next();
          });
      })
      .catch(() => {
        next();
      });
  },
})
export default class Onboarding extends Vue {
  @Inject(TYPES.GET_ONBOARDING_STEP_NAME)
  readonly getOnboardingStepName!: GetOnboardingStepName;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  readonly getOnboardingStepQuery!: GetOnboardingStepQuery;

  @Inject(TYPES.GET_INTERNET_STATUS_QUERY)
  readonly getInternetStatusQuery!: GetInternetStatusQuery;

  $refs!: {
    registration_status: Vue;
  };

  valid_form = false;

  current_step = 1;

  current_substep = 1;

  show_steps = true;

  steps: any = {
    1: { total_steps: 2 },
    2: { total_steps: 2 },
    3: { total_steps: 1 },
    4: { total_steps: 2 },
    5: { total_steps: 1 },
    6: { total_steps: 1 },
    7: { total_steps: 1 },
    8: { total_steps: 2 },
    9: { total_steps: 1 },
  };

  get canContinue() {
    return this.valid_form && this.getInternetStatusQuery.execute();
  }

  get canGoBack() {
    return this.getInternetStatusQuery.execute();
  }

  get show_back_button() {
    return this.current_step > 3 && this.current_step < 8;
  }

  get show_next_button() {
    return this.current_step >= 3 && this.current_step < 8;
  }

  isOnStep(step: number, substep: number) {
    return (step === this.current_step && substep === this.current_substep) || false;
  }

  hideSteps() {
    this.show_steps = false;
  }

  async nextStep() {
    await this.storeData();

    if (this.steps[this.current_step].total_steps > this.current_substep) {
      const new_current_substep = this.current_substep + 1;
      this.current_substep = new_current_substep;
    } else {
      const new_current_step = this.current_step + 1;
      this.current_step = new_current_step;
      this.current_substep = 1;
    }
  }

  backStep() {
    if (this.current_substep > 1) {
      const new_current_substep = this.current_substep - 1;
      this.current_substep = new_current_substep;
    } else {
      const new_current_step = this.current_step - 1;
      this.current_step = new_current_step;
      this.current_substep = this.steps[this.current_step].total_steps;
    }
  }

  async storeData() {
    try {
      // eslint-disable-next-line default-case
      switch (this.current_step) {
        case 3:
          if (this.current_substep === 1) {
            // @ts-ignore
            await this.$refs.upload_documents.upload_documents_view_model.updateStepData();
          }
          break;
        case 4:
          if (this.current_substep === 1) {
            // @ts-ignore
            await this.$refs.personal_information.updateStepData();
          } else if (this.current_substep === 2) {
            // @ts-ignore
            await this.$refs.tax_information.updateStepData();
          }
          break;
        case 5:
          // @ts-ignore
          await this.$refs.financial_information.updateStepData();
          break;
        case 6:
          // @ts-ignore
          await this.$refs.bank_information.bank_information_page_view_model.updateStepData();
          break;
        case 7:
          // @ts-ignore
          await this.$refs.beneficiaries.beneficiaries_page_view_model.updateStepData();
          break;
      }
    } catch (error) {
      if (error.message !== 'Step has already been created.') {
        throw error;
      }
    }
  }

  created() {
    this.$store.dispatch('layout/changeHeaderPosition', 'relative');
  }
}

