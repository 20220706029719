import { v4 } from 'uuid';
import { bankAccountFormat, requiredRule } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import GetFinancialInstitutionsQueryService
  from '@/modules/onboarding/catalogs/financial-institution/application/queries/get-financial-institutions-query-service';
import SearchAgreementsQuery
  from '@/modules/agreements/application/queries/search-agreements-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import CreateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import UpdateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';

// Domain
import { CustomerAgreementEntity } from '@/modules/onboarding/customer-agreement/domain/entities/customer-agreement-entity';
import { AgreementEntity } from '@/modules/agreements/domain/entities/agreement-entity';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import Inject from '@/modules/shared/domain/dependency_injection/inject';

export default class BankInformationPageViewModel {
  @Inject(TYPES.GET_FINANCIAL_INSTITUTIONS_QUERY_SERVICE)
  private readonly get_financial_institution_query!: GetFinancialInstitutionsQueryService;

  @Inject(TYPES.SEARCH_AGREEMENTS_QUERY)
  private readonly searchAgreementsQuery!: SearchAgreementsQuery;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  readonly createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  readonly updateOnboardingStepCommand!: UpdateOnboardingStepCommand;

  readonly step_ids = {
    beneficiaries: v4(),
  };

  loading = false;

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  inputs = {
    financial_institution: '',
    bank_id: '',
    bank_account: '',
    confirm_bank_information: false,
    want_to_be_contacted_to_add_another_bank_account: false,
  };

  user_agreements: Array<CustomerAgreementEntity> = [
    {
      was_accepted: false,
      id: '',
      accepted_on: null,
      agreement_type_id: '',
      description: '',
    },
    {
      was_accepted: false,
      id: '',
      accepted_on: null,
      agreement_type_id: '',
      description: '',
    },
  ];

  rules = {
    required: [requiredRule],
    bank_account: [requiredRule, bankAccountFormat],
  };

  private get financial_institutions() {
    return this.get_financial_institution_query.execute();
  }

  get agreements() {
    return this.searchAgreementsQuery.execute();
  }

  get valid_bank_data() {
    return (
      this.inputs.bank_id
      && this.inputs.bank_account
        .replaceAll(' ', '')
        .match(/[0-9]{18}/)
    );
  }

  setFinancialInstitutionByBankAccountNumber(bank_account_number: string) {
    if (bank_account_number.length > 2) {
      if (this.inputs.financial_institution === '') {
        const financial_institution = this.financial_institutions.find(
          (item) => item.key === bank_account_number.slice(0, 3),
        );

        if (financial_institution) {
          this.inputs.bank_id = financial_institution.id;
          this.inputs.financial_institution = financial_institution.short_name;
        }
      }
    } else {
      this.inputs.financial_institution = '';
    }
  }

  toggleConfirmBankInformationAgreement(confirm: boolean) {
    this.user_agreements[0] = {
      ...this.user_agreements[0],
      accepted_on: confirm
        ? this.datetimeValue.create()
        : this.user_agreements[0].accepted_on,
      was_accepted: confirm,
    };
  }

  toggleWantToBeContactedToAddAnotherBankAccountAgreement(confirm: boolean) {
    this.user_agreements[1] = {
      ...this.user_agreements[1],
      accepted_on: confirm
        ? this.datetimeValue.create()
        : this.user_agreements[1].accepted_on,
      was_accepted: confirm,
    };
  }

  setUserAgreementIdentifiersOnAgreementsLoaded = (new_agreements: Array<AgreementEntity>) => {
    const confirm_bank_information_agreement = new_agreements.find(
      (item) => item.name === 'confirm_bank_information',
    );
    const want_to_be_contacted_to_add_another_bank_account_agreement = new_agreements.find(
      (item) => item.name === 'want_to_be_contacted_to_add_another_bank_account',
    );

    if (confirm_bank_information_agreement) {
      const user_agreement = this.current_step.payload.agreements.find(
        (item: CustomerAgreementEntity) => (
          item.agreement_type_id === confirm_bank_information_agreement.id
        ),
      );

      if (user_agreement) {
        this.user_agreements[0] = user_agreement;
      } else {
        this.user_agreements[0] = {
          ...this.user_agreements[0],
          id: v4(),
          description: confirm_bank_information_agreement.description,
          agreement_type_id: confirm_bank_information_agreement.id,
        };
      }
    }

    if (want_to_be_contacted_to_add_another_bank_account_agreement) {
      const user_agreement = this.current_step.payload.agreements.find(
        (item: CustomerAgreementEntity) => (
          item.agreement_type_id === want_to_be_contacted_to_add_another_bank_account_agreement.id
        ),
      );

      if (user_agreement) {
        this.user_agreements[1] = user_agreement;
      } else {
        this.user_agreements[1] = {
          ...this.user_agreements[1],
          id: v4(),
          description: want_to_be_contacted_to_add_another_bank_account_agreement.description,
          agreement_type_id: want_to_be_contacted_to_add_another_bank_account_agreement.id,
          accepted_on: this.datetimeValue.create(),
        };
      }
    }
  }

  fillOptionalCustomerAgreementsData = () => {
    if (!this.user_agreements[1].accepted_on) {
      this.user_agreements[1] = {
        ...this.user_agreements[1],
        accepted_on: this.datetimeValue.create(),
        was_accepted: false,
      };
    }
  }

  updateStepData = async () => {
    this.loading = true;

    try {
      this.fillOptionalCustomerAgreementsData();
      await this.updateOnboardingStepCommand.execute({
        current_step: this.current_step.current_step,
        id: this.current_step.id,
        payload: {
          ...this.inputs,
          bank_account: this.inputs.bank_account,
          agreements: this.user_agreements,
        },
      });
      await this.createOnboardingStepCommand.execute({
        id: this.step_ids.beneficiaries,
        current_step: 'beneficiaries',
        payload: {
          beneficiaries: [],
          agreements: [],
        },
      });
    } finally {
      this.loading = false;
    }
  };

  initialize = async () => {
    this.current_step = await this.get_on_boarding_step_query.execute('bank_information');
    this.get_financial_institution_query.execute();
    this.inputs = {
      bank_account: this.current_step.payload.bank_account,
      bank_id: this.current_step.payload.bank_id,
      confirm_bank_information: this.current_step.payload.confirm_bank_information,
      want_to_be_contacted_to_add_another_bank_account:
        this.current_step.payload.want_to_be_contacted_to_add_another_bank_account,
      financial_institution: this.current_step.payload.financial_institution,
    };
  }
}
