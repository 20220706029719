





































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'PhoneCountryCodeSelect' })
export default class PhoneCountryCodeSelect extends Vue {
  @Prop(Array) rules?: Array<any>

  country_code = '+52';

  prefixes = [
    {
      key: '+1',
      value: '+1',
      image: 'US.svg',
    },
    {
      key: '+52',
      value: '+52',
      image: 'MX.svg',
    },
  ];

  handleSelectCountry() {
    this.$emit('handleSelectCountry', this.country_code);
  }
}
