






































































import { Vue, Component, Watch } from 'vue-property-decorator';
import VerifyIdentityPageViewModel
  from '@/vue-app/view-models/components/on-boarding/verify-identity/verify-identity-page-view-model';
import ErrorVerifyIdentity from '@/vue-app/components/onboarding/verify-identity/ErrorVerifyIdentity.vue';
import ErrorManualReview from '@/vue-app/components/onboarding/verify-identity/ErrorManualReview.vue';
import TYPES from '@/types';

// Application
import { CreateOnboardingStepCommand } from '@/modules/onboarding/status/application/commands';
import ResetIdentityVerificationStateManager
  from '@/modules/onboarding/identity-verification/application/services/reset-identity-verification-state-manager';

// Domain
import { VueNotifier } from '@/modules/shared/infrastructure/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

@Component({
  name: 'VerifyIdentityPage',
  components: {
    ErrorVerifyIdentity,
    ErrorManualReview,
  },
})
export default class VerifyIdentityPage extends Vue {
  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  readonly createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly vueNotifier!: VueNotifier;

  @Inject(TYPES.IDENTITY_VERIFICATION_RESET_STATE_MANAGER)
  private readonly reset_identity_verification_state_manager!:
    ResetIdentityVerificationStateManager;

  verify_identity_page_view_model = Vue.observable(new VerifyIdentityPageViewModel());

  async handleNext() {
    this.verify_identity_page_view_model.is_loading = true;

    try {
      await this.createOnboardingStepCommand.execute({
        id: this.verify_identity_page_view_model.step_ids.upload_documents,
        current_step: 'upload_documents',
        payload: {},
      });
      this.$emit('handleNext');
    } catch {
      this.vueNotifier.showErrorNotification('Ocurrio un error');
    } finally {
      this.verify_identity_page_view_model.is_loading = false;
    }
  }

  handleBack() {
    this.reset_identity_verification_state_manager.execute();
    this.verify_identity_page_view_model.verification_status = 'pending';
    this.$emit('handleBack');
  }

  @Watch('verify_identity_page_view_model.last_event_data', { deep: true })
  onLastEventDataChange() {
    this.verify_identity_page_view_model.updateVerificationStatus();
  }

  async created() {
    window.scrollTo(100, 0);
    await this.verify_identity_page_view_model.setCurrentStep();
    this.verify_identity_page_view_model.updateVerificationStatus();
  }
}
