// Application
import { GetOnboardingCurrentStepQuery } from '@/modules/onboarding/status/application/queries';

export default class RegistrationStatusViewModel {
  private readonly view!: any;

  private readonly get_on_boarding_current_step_query!: GetOnboardingCurrentStepQuery;

  readonly steps = [
    'ValidationProcess',
    'SignDocuments',
    'InitialContribution',
  ];

  current_step = 1;

  get current_component() {
    return this.steps[this.current_step - 1];
  }

  public constructor(view: any) {
    this.view = view;
    this.get_on_boarding_current_step_query = new GetOnboardingCurrentStepQuery();
  }

  nextStep = () => {
    if (this.steps.length > this.current_step) {
      this.current_step += 1;
    } else {
      this.view.$emit('handleNext');
    }
  }

  initialize = async () => {
    this.view.$emit('hideSteps');

    const current_on_boarding_step = await this.get_on_boarding_current_step_query.execute();

    this.setCurrentStep(current_on_boarding_step.current_step);
  }

  private setCurrentStep(step_name: string) {
    switch (step_name) {
      case 'validation_process':
        this.current_step = 1;
        break;
      case 'sign_documents':
        this.current_step = 2;
        break;
      case 'initial_contribution':
        this.current_step = 3;
        break;
      default: this.current_step = 1;
    }
  }
}
