






















































































import { Vue, Component } from 'vue-property-decorator';
import { requiredRule } from '@/vue-app/utils/form-rules';
import Card from '../../Card.vue';
import NationalitiesSectionViewModel
  from '@/vue-app/view-models/components/on-boarding/personal-info/tax-information/nationalities-section-view-model';

@Component({
  name: 'NationalitiesSection',
  components: { Card },
})
export default class NationalitiesSection extends Vue {
  nationalities_section_view_model = Vue.observable(new NationalitiesSectionViewModel());

  required_rule = [requiredRule];

  async created() {
    await this.nationalities_section_view_model.initialize();
  }
}
