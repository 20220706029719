












































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { v4 } from 'uuid';
import Card from '../Card.vue';
import PersonalInfoViewModel
  from '@/vue-app/view-models/components/on-boarding/personal-info/personal-info-view-model';
import TYPES from '@/types';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';

// Domain
import { AgreementEntity } from '@/modules/agreements/domain/entities/agreement-entity';
import { CustomerAgreementEntity }
  from '@/modules/onboarding/status/domain/entities/customer-agreement-entity';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';

@Component({
  name: 'PersonalInfo',
  components: { CustomAutocomplete, Card },
})
export default class PersonalInfo extends Vue {
  personal_info_view_model = Vue.observable(new PersonalInfoViewModel());

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  current_step: StepEntity = {
    current_step: '',
    payload: {},
    id: '',
  };

  loaded_data = false;

  form_validity = false;

  confirm_data = false;

  user_agreement: CustomerAgreementEntity = {
    agreement_type_id: '',
    accepted_on: null,
    id: '',
    was_accepted: false,
    description: '',
  };

  @Watch('confirm_data')
  handleConfirmData(new_confirm_data: boolean) {
    if (new_confirm_data && this.loaded_data) {
      this.user_agreement = {
        ...this.user_agreement,
        was_accepted: true,
        accepted_on: this.datetimeValue.create(),
      };
    }
  }

  @Watch('personal_info_view_model.agreements')
  setAgreementData(new_agreements: Array<AgreementEntity>) {
    const agreement = new_agreements.find(
      (item) => item.name === 'confirmation_of_personal_data',
    );

    if (agreement) {
      const user_agreement = this.current_step.payload.agreements.find(
        (item: CustomerAgreementEntity) => item.agreement_type_id === agreement.id,
      );

      if (user_agreement) {
        this.user_agreement = user_agreement;
      } else {
        this.user_agreement = {
          ...this.user_agreement,
          id: v4(),
          description: agreement.description,
          agreement_type_id: agreement.id,
        };
      }
    }
  }

  @Watch('personal_info_view_model.person')
  onPersonChange() {
    if (this.personal_info_view_model.person.id && this.personal_info_view_model.is_loading) {
      this.personal_info_view_model.createPersonPresenter();
      this.personal_info_view_model.is_loading = false;
    }
  }

  async created() {
    this.onPersonChange();
    this.current_step = await this.personal_info_view_model.current_step;
    this.setAgreementData(this.personal_info_view_model.agreements);
    this.confirm_data = this.current_step.payload.confirm_data.personal;

    setTimeout(() => {
      this.loaded_data = true;
    }, 1000);
  }
}
