import TYPES from '@/types';

// Infrastructure
import Functions from '@/modules/shared/infrastructure/utils/functions';

// Domain
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { VueNotifier } from '@/modules/shared/infrastructure/notifiers/message_notifier';

export default class UploadFileCardViewModel {
  @Inject(TYPES.UTIL_FUNCTIONS)
  private functions!: Functions;

  @Inject(TYPES.NOTIFIER)
  private readonly vue_notifier!: VueNotifier;

  selected_file: File | null = null;

  hide_file_size = false;

  get button_file_call_to_action() {
    return this.selected_file
      ? 'Adjuntar otro archivo'
      : 'Adjuntar archivo';
  }

  getFileBase64 = async (selected_file: File) => (
    this.functions.convert_file_to_base_64(selected_file)
  )

  setExistentSelectedFile = (selected_file: File) => {
    this.hide_file_size = true;
    this.selected_file = selected_file;
  }

  showSelectError = (message: string) => {
    this.vue_notifier.showErrorNotification(message);
  }

  resetStatus = (selected_file?: File) => {
    if (selected_file) {
      this.setExistentSelectedFile(selected_file);
    } else {
      this.selected_file = null;
      this.hide_file_size = false;
    }
  }
}
