import Vue from 'vue';
import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import { BLUE } from '@/constants/colors.json';

// APPLICATION
import { GetIdentityVerificationServiceQuery } from '@/modules/onboarding/identity-verification/application/queries';
import { IdentityVerificationStatusSubscription } from '@/modules/onboarding/identity-verification/application/subscriptions';

// DOMAIN
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@modules/shared/domain/di/inject';

export default class VerifyYourIdentityPageViewModel {
  @Inject(TYPES.GET_IDENTITY_VERIFICATION_SERVICE_QUERY)
  readonly getIdentityVerificationServiceQuery!: GetIdentityVerificationServiceQuery;

  @Inject(TYPES.IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION)
  readonly identityVerificationStatusSubscription!: IdentityVerificationStatusSubscription;

  @Inject(TYPES.NOTIFIER)
  readonly notifier!: MessageNotifier;

  readonly mati_background_color = BLUE;

  with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  current_flow_uuid = uuid();

  identityVerificationService = {
    client_id: '5d8baa21416f29001b2fb1a8',
    flow_id: '',
  };

  private view: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  get user_id() {
    return sessionStorage.getItem('user_id');
  }

  refreshFlowUUID = () => {
    this.current_flow_uuid = uuid();
  };

  finishIdentityVerificationProcess = () => {
    this.view.$emit('handleNext');
  };

  getMatiEnvironment = async () => {
    try {
      const response = await this.getIdentityVerificationServiceQuery.execute();
      this.identityVerificationService.flow_id = response.flow_id;
    } catch (error) {
      this.notifier.showErrorNotification('Ocurrió un error con el servicio de verificación de identidad');
    }
  };

  initialize = async () => {
    if (!this.with_dummies) {
      const mati_script = document.createElement('script');
      await this.getMatiEnvironment();
      mati_script.setAttribute('src', 'https://web-button.metamap.com/button.js');
      document.head.appendChild(mati_script);
    }

    this.identityVerificationStatusSubscription.execute();
  };
}
