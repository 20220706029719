import { render, staticRenderFns } from "./ColumbusAdvisor.vue?vue&type=template&id=0cd9341e&scoped=true&"
import script from "./ColumbusAdvisor.vue?vue&type=script&lang=ts&"
export * from "./ColumbusAdvisor.vue?vue&type=script&lang=ts&"
import style0 from "./ColumbusAdvisor.vue?vue&type=style&index=0&id=0cd9341e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd9341e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VCol,VExpandTransition,VForm,VIcon,VRadio,VRadioGroup,VRow,VTooltip})
