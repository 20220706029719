














































import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import Card from '../Card.vue';
import UploadFileCardViewModel
  from '@/vue-app/view-models/components/on-boarding/documents/upload-file-card-view-model';

@Component({
  name: 'UploadFileCard',
  components: { Card },
})
export default class UploadFileCard extends Vue {
  @Prop({ type: Number, default: null })
  file_size!: number;

  @Prop({ type: String, default: null })
  accept!: string;

  @Prop({ type: File, default: null })
  initial_file!: File;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({
    type: String,
    default: 'Ocurrio un error subir el archivo seleccionado.',
  })
  error_message!: string;

  @Prop({ default: false, required: false })
  loading!: boolean;

  upload_file_card_view_model = Vue.observable(new UploadFileCardViewModel());

  async selectFile({ target }: any) {
    const selected_file = target.files[0];

    if (!selected_file) return;

    if (
      selected_file.size <= this.file_size
      && selected_file.size >= 1000
    ) {
      try {
        this.upload_file_card_view_model.hide_file_size = false;
        const base64 = await this.upload_file_card_view_model.getFileBase64(selected_file);
        this.$emit('handleSelectedFile', base64, selected_file.type, selected_file.name);
      } catch {
        this.upload_file_card_view_model.showSelectError(this.error_message);
      }
    } else {
      this.upload_file_card_view_model.showSelectError(this.error_message);
    }
  }

  @Watch('initial_file')
  setInitialFile(selected_file: File) {
    if (selected_file) {
      this.upload_file_card_view_model.setExistentSelectedFile(selected_file);
    }
  }

  created() {
    this.setInitialFile(this.initial_file);
  }
}
