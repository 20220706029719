

















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Card from '../Card.vue';
import PoliticallyExposedPerson from './PoliticallyExposedPerson.vue';
import RealOwnerOfResources from './RealOwnerOfResources.vue';
import RealResourceProvider from './RealResourceProvider.vue';
import DisclaimersViewModel from '@/vue-app/view-models/components/on-boarding/financial-information/disclaimers-view-model';

@Component({
  name: 'Disclaimers',
  components: {
    Card,
    PoliticallyExposedPerson,
    RealOwnerOfResources,
    RealResourceProvider,
  },
})
export default class Disclaimers extends Vue {
  private readonly disclaimers_view_model = Vue.observable(
    new DisclaimersViewModel(),
  );

  @Watch('disclaimers_view_model.confirmNoPoliticallyExposed')
  handleConfirmNoPoliticallyExposedChange(new_confirmation: boolean) {
    this.disclaimers_view_model.handleAgreementChange('not_politically_exposed_person', new_confirmation);
  }

  @Watch('disclaimers_view_model.confirmResourcesProvider')
  handleConfirmResourcesProviderChange(new_confirmation: boolean) {
    this.disclaimers_view_model.handleAgreementChange('resource_provider', new_confirmation);
  }

  @Watch('disclaimers_view_model.confirmResourceOwner')
  handleConfirmResourceOwnerChange(new_confirmation: boolean) {
    this.disclaimers_view_model.handleAgreementChange('resource_owner', new_confirmation);
  }

  @Watch('disclaimers_view_model.confirm_acting_on_its_own')
  handleConfirmActingOnItsOwn(new_confirmation: boolean) {
    this.disclaimers_view_model.handleAgreementChange('acting_on_its_own', new_confirmation);
  }

  created() {
    this.disclaimers_view_model.initialize();
  }
}
