export default class Base64DataUriToFileConverter {
  static async convert(base64: string, mime_type: string, filename: string): Promise<File> {
    const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
    if (with_dummies) return new File([], filename, { type: mime_type });

    const response = await fetch(base64);
    const buffer = await response.arrayBuffer();

    return new File([buffer], filename, { type: mime_type });
  }
}
