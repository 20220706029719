




































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import AddressFormViewModel from '@/vue-app/view-models/components/on-boarding/personal-info/address-form-view-model';
import Card from '../Card.vue';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';

// Domain
import { CountryEntity } from '@/modules/onboarding/catalogs/country/domain/entities/country-entity';
import { SuburbInZipCodeEntity } from '@/modules/onboarding/catalogs/suburb/domain/entities';

@Component({
  name: 'AddressForm',
  components: {
    Card,
    CustomAutocomplete,
  },
})
export default class AddressForm extends Vue {
  address_form_view_model = Vue.observable(new AddressFormViewModel());

  @Watch('address_form_view_model.countries')
  onCountriesChange(new_countries: Array<CountryEntity>) {
    this.address_form_view_model.setCustomerAddressCountry(new_countries);
  }

  @Watch('address_form_view_model.suburbs_in_zip_code')
  onSuburbsInZipCodeChange(new_suburbs_in_zip_code: Array<SuburbInZipCodeEntity>) {
    this.address_form_view_model.fillCustomerAddressSuburbData(new_suburbs_in_zip_code[0]);
  }

  async created() {
    await this.address_form_view_model.initialize();
    this.address_form_view_model.setCustomerAddressCountry(
      this.address_form_view_model.countries,
    );
  }
}
