


















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BankInformationPageViewModel
  from '@/vue-app/view-models/components/on-boarding/bank-information/bank-information-page-view-model';
import Card from '../Card.vue';

// Domain
import { AgreementEntity } from '@/modules/agreements/domain/entities/agreement-entity';

@Component({
  name: 'BankInformationPage',
  components: { Card },
})
export default class BankInformationPage extends Vue {
  bank_information_page_view_model = Vue.observable(new BankInformationPageViewModel());

  @Watch('bank_information_page_view_model.inputs.bank_account')
  onBankAccountChange(bank_account: string) {
    this.bank_information_page_view_model.setFinancialInstitutionByBankAccountNumber(bank_account);
  }

  @Watch('bank_information_page_view_model.agreements')
  onAgreementsChange(new_agreements: Array<AgreementEntity>) {
    this.bank_information_page_view_model
      .setUserAgreementIdentifiersOnAgreementsLoaded(new_agreements);
  }

  @Watch('bank_information_page_view_model.inputs.confirm_bank_information')
  onConfirmBankInformationChange(confirm: boolean) {
    this.bank_information_page_view_model.toggleConfirmBankInformationAgreement(confirm);
  }

  @Watch(
    'bank_information_page_view_model.inputs.want_to_be_contacted_to_add_another_bank_account',
  )
  onConfirmWantToAddAnotherBankAccountAgreement(confirm: boolean) {
    this.bank_information_page_view_model.toggleWantToBeContactedToAddAnotherBankAccountAgreement(
      confirm,
    );
  }

  async created() {
    window.scrollTo(0, 0);
    await this.bank_information_page_view_model.initialize();
    this.bank_information_page_view_model
      .setUserAgreementIdentifiersOnAgreementsLoaded(
        this.bank_information_page_view_model.agreements,
      );
  }
}
