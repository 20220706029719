import { StepDto } from '@/modules/onboarding/status/domain/dtos';
import { SectionBase } from '@/modules/onboarding/status/domain/services/onboarding-step/step';

export default class TaxInformation implements SectionBase {
  getStepAndSubStep(): StepDto {
    return {
      step: 4,
      substep: 2,
    };
  }
}
