




































import { Component, Vue, PropSync } from 'vue-property-decorator';
import PoliticallyExposedPersonViewModel from '@/vue-app/view-models/components/on-boarding/financial-information/politically-exposed-person-view-model';

@Component({ name: 'PoliticallyExposedPerson' })
export default class PoliticallyExposedPerson extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  private readonly politically_exposed_person_view_model = Vue.observable(
    new PoliticallyExposedPersonViewModel(this),
  );

  accept = null;
}
