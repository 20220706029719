import { v4 } from 'uuid';

import TYPES from '@/types';
import { requiredRule } from '@/vue-app/utils/form-rules';

// APPLICATION
import { SendProofOfAddressCommandService } from '@/modules/onboarding/documents/application/commands';
import Base64DataUriToFileConverter from '@/modules/onboarding/file/application/services/base64-data-uri-to-file-converter';
import MimeTypeFromBase64 from '@/modules/onboarding/file/application/services/mime-type-from-base64';
import GetCustomerBase64DocumentQuery from '@/modules/onboarding/customer-document/application/queries/get-customer-base64-document-query';

// DOMAIN
import { CreateDocumentDto } from '@/modules/onboarding/documents/domain/dtos/create-document-dto';
import { DocumentEntity } from '@/modules/onboarding/documents/domain/entities/document-entity';
import Inject from '@/modules/shared/domain/di/inject';
import SearchDocumentsQuery from '@/modules/onboarding/documents/application/queries/search-documents-query';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class PersonaInfoPage2ViewModel {
  @Inject(TYPES.SEND_PROOF_OF_ADDRESS_COMMAND_SERVICE)
  readonly send_proof_of_address_command_service!: SendProofOfAddressCommandService;

  @Inject(TYPES.SEARCH_DOCUMENTS_QUERY)
  readonly search_documents_query!: SearchDocumentsQuery;

  @Inject(TYPES.GET_CUSTOMER_BASE64_DOCUMENT_QUERY)
  get_customer_base64_document_query!: GetCustomerBase64DocumentQuery;

  @Inject(TYPES.NOTIFIER)
  readonly notifier!: MessageNotifier;

  customer_documents!: Array<DocumentEntity>;

  fiscal_proof_of_address = {
    request_id: '',
    accepted_file_types: 'image/*,.pdf',
    file: null as null | File,
    preview: null as null | File,
    limit_file_size: 4 * 1024 * 1024,
    loading: false,
    input_rules: [
      requiredRule,
    ],
  }

  constructor() {
    this.fiscal_proof_of_address.request_id = v4();
  }

  async getCustomerDocuments() {
    try {
      this.fiscal_proof_of_address.loading = true;
      this.customer_documents = await this.search_documents_query.execute();
      await this.setInitialDocumentsData(this.customer_documents);
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al obtener los documentos de usuario');
    } finally {
      this.fiscal_proof_of_address.loading = false;
    }
  }

  setInitialDocumentsData = async (new_documents: Array<DocumentEntity>) => {
    const fiscal_proof_of_address = new_documents.find(
      (item) => item.document_type.name === 'proof_of_fiscal_address',
    );

    if (fiscal_proof_of_address) {
      const file_name = fiscal_proof_of_address.file.name;
      const fiscal_proof_of_address_doc = await this.downloadCustomerDocumentPreview(
        fiscal_proof_of_address.customer_document_id, file_name,
      );
      this.fiscal_proof_of_address.preview = fiscal_proof_of_address_doc;
      this.fiscal_proof_of_address.file = fiscal_proof_of_address_doc;
    }
  };

  downloadCustomerDocumentPreview = async (customer_document_id: string, file_name: string) => {
    this.fiscal_proof_of_address.loading = true;
    try {
      const base64 = await this.get_customer_base64_document_query.execute(customer_document_id);
      const mime_type = MimeTypeFromBase64.getFileMimeType(base64);
      return Base64DataUriToFileConverter.convert(base64, mime_type, file_name);
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al descargar la previsualización del documento');
      return null;
    } finally {
      this.fiscal_proof_of_address.loading = false;
    }
  };

  selectFiscalProofOfAddress = async (base64: string, mime_type: string, file_name: string) => {
    try {
      this.fiscal_proof_of_address.loading = true;
      const proof_of_address: CreateDocumentDto = {
        id: this.fiscal_proof_of_address.request_id,
        name: file_name,
        mime_type,
        file_data: base64,
        document_type_id: '5f767168-e0dd-499c-96bb-e361df4e78a2',
      };
      await this.send_proof_of_address_command_service.execute(proof_of_address);
      const fiscal_proof_of_address_file = await Base64DataUriToFileConverter.convert(
        base64,
        mime_type,
        proof_of_address.name,
      );
      this.fiscal_proof_of_address.preview = fiscal_proof_of_address_file;
      this.fiscal_proof_of_address.file = fiscal_proof_of_address_file;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.fiscal_proof_of_address.loading = false;
    }
  };

  async initialize() {
    await this.getCustomerDocuments();
  }
}
