















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { v4 } from 'uuid';
import { notNull, requiredRule, phoneFormat } from '@/vue-app/utils/form-rules';
import Card from '../Card.vue';
import PhoneCountryCodeSelect from './PhoneCountryCodeSelect.vue';
import TYPES from '@/types';

// Application
import SearchAgreementsQuery
  from '@/modules/agreements/application/queries/search-agreements-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { CustomerAgreementEntity }
  from '@/modules/onboarding/status/domain/entities/customer-agreement-entity';
import { AgreementEntity } from '@/modules/agreements/domain/entities/agreement-entity';
import {
  CustomerPhoneEntity,
  CustomerCellphoneEntity,
} from '@/modules/onboarding/phone/domain/entities';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/di/inject';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';

@Component({
  name: 'ContactInfo',
  components: { Card, PhoneCountryCodeSelect },
})
export default class ContactInfo extends Vue {
  @Inject(TYPES.SEARCH_AGREEMENTS_QUERY)
  readonly searchAgreementsQuery!: SearchAgreementsQuery;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  current_step: StepEntity = {
    current_step: '',
    payload: {},
    id: '',
  };

  mounted_info = false;

  phones = {
    office: {
      customer_phone_default: false,
      customer_phone_id: '',
      phone: {
        id: '',
        number: '',
        country_code: '',
        name: 'office',
        extension: '',
      },
    } as CustomerPhoneEntity,
    home: {
      customer_phone_default: false,
      customer_phone_id: '',
      phone: {
        id: '',
        number: '',
        country_code: '',
        name: 'house',
        extension: '',
      },
    } as CustomerPhoneEntity,
  };

  cell_phone = {
    cellphone: {
      id: '',
      number: '',
      country_code: '',
      name: '',
      extension: '',
    },
    customer_cellphone_id: '',
  } as CustomerCellphoneEntity;

  allow_whatsapp: null | boolean = null;

  rules = {
    required: [requiredRule],
    not_null: [notNull],
    phone_format: [phoneFormat],
    phone_format_required: [requiredRule, phoneFormat],
  };

  user_agreement: CustomerAgreementEntity = {
    id: '',
    agreement_type_id: '',
    description: '',
    was_accepted: false,
    accepted_on: null,
  };

  get phonesData() {
    const defaultPhonesData = [
      {
        customer_phone_id: '',
        customer_phone_default: true,
        phone: {
          number: '',
          country_code: '+52',
          id: '',
          name: 'house',
          extension: '',
        },
      },
      {
        customer_phone_id: '',
        customer_phone_default: false,
        phone: {
          number: '',
          country_code: '+52',
          id: '',
          name: 'office',
          extension: '',
        },
      },
    ];

    try {
      const stepPhonesData = this.current_step.payload.phones;

      if (stepPhonesData) {
        return stepPhonesData;
      }

      return defaultPhonesData;
    } catch {
      return defaultPhonesData;
    }
  }

  get cellPhonesData() {
    try {
      return this.current_step.payload.cellphones;
    } catch {
      return [
        {
          customer_cellphone_id: '',
          cellphone: {
            number: '',
            country_code: '+52',
            id: '',
            name: 'personal',
          },
        },
      ];
    }
  }

  get agreements() {
    return this.searchAgreementsQuery.execute();
  }

  setPhones() {
    const home_phone = this.phonesData.find(
      (customer_phone: CustomerPhoneEntity) => customer_phone.phone.name === 'house',
    );
    const office_phone = this.phonesData.find(
      (customer_phone: CustomerPhoneEntity) => customer_phone.phone.name === 'office',
    );

    if (home_phone) {
      this.phones.home = home_phone;
    } else {
      const home_phone_id = v4();
      this.phones.home.customer_phone_default = false;
      this.phones.home.customer_phone_id = home_phone_id;
      this.phones.home.phone.id = home_phone_id;
      this.phones.home.phone.country_code = '+52';
    }

    if (office_phone) {
      this.phones.office = office_phone;
    } else {
      const office_phone_id = v4();
      this.phones.office.customer_phone_id = office_phone_id;
      this.phones.office.phone.id = office_phone_id;
      this.phones.office.phone.country_code = '+52';
    }
  }

  setCellPhone() {
    const cellphone_id = v4();
    // eslint-disable-next-line prefer-destructuring
    this.cell_phone = this.cellPhonesData[0] || {
      customer_cellphone_id: cellphone_id,
      cellphone: {
        number: '',
        country_code: '+52',
        id: cellphone_id,
        name: 'personal',
        default: true,
      },
    };
  }

  @Watch('agreements')
  setAgreementData(new_agreements: Array<AgreementEntity>) {
    const agreement = new_agreements.find(
      (item) => item.name === 'allow_whatsapp_contact',
    );

    if (agreement && this.current_step.payload.agreements) {
      const user_agreement = this.current_step.payload.agreements.find(
        (item: CustomerAgreementEntity) => item.agreement_type_id === agreement.id,
      );

      if (user_agreement) {
        this.user_agreement = user_agreement;
      } else {
        this.user_agreement = {
          ...this.user_agreement,
          id: v4(),
          description: agreement.description,
          agreement_type_id: agreement.id,
        };
      }
    }
  }

  @Watch('allow_whatsapp')
  handleAgreementChange(new_allow_whatsapp: boolean) {
    this.user_agreement = {
      ...this.user_agreement,
      accepted_on: new_allow_whatsapp !== null
        ? this.datetimeValue.create()
        : this.user_agreement.accepted_on,
      was_accepted: new_allow_whatsapp,
    };
  }

  async created() {
    this.current_step = await this.get_on_boarding_step_query.execute('personal_information');
    this.setAgreementData(this.searchAgreementsQuery.execute());
    this.allow_whatsapp = this.current_step.payload.personal_information.allow_whatsapp_contact;
    this.setPhones();
    this.setCellPhone();

    setTimeout(() => {
      this.mounted_info = true;
    }, 1000);
  }
}
