import Inject from '@/modules/shared/domain/di/inject';
import { StepDto } from '@/modules/onboarding/status/domain/dtos';
import { SectionBase } from '@/modules/onboarding/status/domain/services/onboarding-step/step';
import { StateManager } from '@/modules/onboarding/status/domain/state/state-manager';
import TYPES from '../../../../types';

export default class ValidationProcess implements SectionBase {
  @Inject(TYPES.ONBOARDING_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  getStepAndSubStep(): StepDto {
    return {
      step: 8,
      substep: 2,
    };
  }
}
