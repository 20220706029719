




































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'Navigation' })
export default class Navigation extends Vue {
  @Prop({ default: 1 })
  selected!: number;

  scroll = 0;

  items = [
    'REGISTRO',
    'VERIFICACIÓN DE<br /> IDENTIDAD',
    'CARGA DE<br/>DOCUMENTOS',
    'INFORMACIÓN<br/>PERSONAL',
    'INFORMACIÓN<br/>FINANCIERA',
    'INFORMACIÓN<br/>BANCARIA',
    'BENEFICIARIOS',
    'CONFIRMACIÓN',
  ];

  created() {
    window.addEventListener('scroll', () => {
      this.scroll = window.scrollY;
    }, { passive: true });
  }

  unmounted() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.removeEventListener('scroll', () => {});
  }
}

