// Domain
import { PersonEntity } from '../../domain/entities/person-entity';

const GENDERS: Record<string, string> = {
  Female: 'Femenino',
  Male: 'Masculino',
};

export default class PersonPresenter {
  private person_entity: PersonEntity

  private formatted_date_of_birth?: string;

  public constructor(person_entity: PersonEntity) {
    this.person_entity = person_entity;
  }

  get humanized_date_of_birth() {
    if (!this.formatted_date_of_birth) {
      this.formatted_date_of_birth = this.person_entity.date_of_birth.split('-').reverse().join('-');
    }

    return this.formatted_date_of_birth;
  }

  get translated_gender() {
    return GENDERS[this.person_entity.gender.name];
  }
}
