







































































import { Component, Vue } from 'vue-property-decorator';
import Card from '@/vue-app/components/onboarding/Card.vue';
import ColumbusAdvisorViewModel from '@/vue-app/view-models/components/on-boarding/personal-info/columbus-advisor-view-model';

@Component({ name: 'ColumbusAdvisor', components: { Card } })
export default class ColumbusAdvisor extends Vue {
  columbus_advisor_view_model = Vue.observable(new ColumbusAdvisorViewModel());
}
