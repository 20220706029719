





































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ModalError from '@/vue-app/components/onboarding/alerts/ModalError.vue';
import { NipPageViewModel } from '@/vue-app/view-models/components/register/nip-page-view-model';

@Component({
  name: 'NipPage',
  components: { ModalError },
})
export default class NipPage extends Vue {
  nip_page_view_model = Vue.observable(new NipPageViewModel(this));

  @Watch('nip_page_view_model.geolocation_permission_granted')
  handlePermissionChange(new_permission_status: boolean) {
    this.nip_page_view_model.handlePermissionChange(new_permission_status);
  }

  async created() {
    await this.nip_page_view_model.initialize();
  }
}

