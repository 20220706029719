

































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { v4 } from 'uuid';
import { requiredRule } from '@/vue-app/utils/form-rules';
import Card from '../Card.vue';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';
import TYPES from '@/types';

// Application
import GetAddressQueryService
  from '@/modules/onboarding/address/application/queries/get-address-query-service';
import GetCountriesQueryService
  from '@/modules/onboarding/catalogs/country/application/queries/get-countries-query-service';
import { GetSuburbsInZipCodeQuery } from '@/modules/onboarding/catalogs/suburb/application/queries';
import SearchAgreementsQuery
  from '@/modules/agreements/application/queries/search-agreements-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { CustomerAddressEntity }
  from '@/modules/onboarding/address/domain/entities/customer-address-entity';
import { CustomerAgreementEntity }
  from '@/modules/onboarding/status/domain/entities/customer-agreement-entity';
import { AgreementEntity } from '@/modules/agreements/domain/entities/agreement-entity';
import { CountryEntity }
  from '@/modules/onboarding/catalogs/country/domain/entities/country-entity';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';

@Component({
  name: 'AddressInfo',
  components: { Card, CustomAutocomplete },
})
export default class AddressInfo extends Vue {
  @Inject(TYPES.GET_ADDRESS_ONBOARDING_QUERY_SERVICE)
  readonly getAddressQueryService!: GetAddressQueryService;

  @Inject(TYPES.GET_COUNTRIES_QUERY_SERVICE)
  readonly getCountriesQueryService!: GetCountriesQueryService;

  @Inject(TYPES.GET_SUBURBS_IN_ZIP_CODE_QUERY)
  readonly getSuburbsInZipCodeQuery!: GetSuburbsInZipCodeQuery;

  @Inject(TYPES.SEARCH_AGREEMENTS_QUERY)
  readonly searchAgreementsQuery!: SearchAgreementsQuery;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  mounted_info = false;

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  inputs: CustomerAddressEntity = {
    customer_address_proof_of_address_id: '',
    customer_address_id: '',
    customer_address_default: false,
    address: {
      id: '',
      street: '',
      external_number: '',
      interior_number: '',
      zip_code: '',
      settlement: '',
      municipality: '',
      state: '',
      city: '',
      address_type: 'personal',
      country: {
        id: '',
        name: '',
      },
      created_at: new Date().toUTCString(),
      updated_at: new Date().toUTCString(),
    },
  };

  user_agreement: CustomerAgreementEntity = {
    accepted_on: null,
    agreement_type_id: '',
    was_accepted: false,
    id: '',
    description: '',
  };

  form_validity = false;

  rules = {
    required: [requiredRule],
  };

  confirm_data = false;

  get countries() {
    return this.getCountriesQueryService.execute();
  }

  get agreements() {
    return this.searchAgreementsQuery.execute();
  }

  get suburbsInZipCode() {
    if (this.inputs.address.zip_code.length === 5) {
      const response = this.getSuburbsInZipCodeQuery.execute(this.inputs.address.zip_code);

      if (response.length) {
        this.inputs.address.municipality = response[0].municipality_name;
        this.inputs.address.state = response[0].state_name;
        this.inputs.address.city = response[0].city_name;
      }

      return response;
    }

    this.inputs.address.municipality = '';
    this.inputs.address.state = '';
    this.inputs.address.settlement = '';

    return [];
  }

  get addresses() {
    return this.current_step.payload.addresses;
  }

  get customer_addresses() {
    return this.getAddressQueryService.internalExecute();
  }

  @Watch('agreements')
  setAgreementData(new_agreements: Array<AgreementEntity>) {
    const agreement = new_agreements.find(
      (item) => item.name === 'address_confirmation',
    );

    if (agreement && this.current_step.payload.agreements) {
      const user_agreement = this.current_step.payload.agreements.find(
        (item: CustomerAgreementEntity) => item.agreement_type_id === agreement.id,
      );

      if (user_agreement) {
        this.user_agreement = user_agreement;
      } else {
        this.user_agreement = {
          ...this.user_agreement,
          id: v4(),
          description: agreement.description,
          agreement_type_id: agreement.id,
        };
      }
    }
  }

  @Watch('countries')
  setDefaultCountry(new_countries: Array<CountryEntity>) {
    if (!this.inputs.address.country.id) {
      const default_country = new_countries.find((country) => country.value === 'México');

      if (default_country) {
        this.inputs.address.country = {
          id: default_country.id,
          name: default_country.value,
        };
      }
    }
  }

  @Watch('confirm_data')
  handleConfirmData(new_confirm_data: boolean) {
    if (new_confirm_data && this.mounted_info) {
      this.user_agreement = {
        ...this.user_agreement,
        was_accepted: true,
        accepted_on: this.datetimeValue.create(),
      };
    }
  }

  @Watch('customer_addresses')
  setAddress() {
    const address = this.addresses.find(
      (item: CustomerAddressEntity) => item.address.address_type === 'personal',
    );

    if (address) {
      // eslint-disable-next-line no-unused-expressions
      this.suburbsInZipCode;
      this.inputs = address;
    } else {
      const customer_address = this.customer_addresses.find(
        (item) => item.address.address_type === 'personal',
      );
      if (customer_address) {
        this.inputs = customer_address;
      } else {
        const id = v4();
        this.inputs.customer_address_id = id;
        this.inputs.customer_address_default = true;
        this.inputs.address.id = id;
      }
    }
  }

  async created() {
    this.current_step = await this.get_on_boarding_step_query.execute('personal_information');
    this.setAgreementData(this.searchAgreementsQuery.execute());
    this.setDefaultCountry(this.countries);
    this.setAddress();
    this.confirm_data = this.current_step.payload.confirm_data.address;

    setTimeout(() => {
      this.mounted_info = true;
    }, 1000);
  }
}
