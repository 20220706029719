import { StepDto } from '@/modules/onboarding/status/domain/dtos';
import { SectionBase } from '@/modules/onboarding/status/domain/services/onboarding-step/step';
import { IdentityVerificationPayload }
  from '@/modules/onboarding/status/domain/dtos/step-payloads/identity-verification-payload';

export default class IdentityVerification implements SectionBase {
  getStepAndSubStep(payload: IdentityVerificationPayload): StepDto {
    if (payload.status === 'pending') {
      return {
        step: 1,
        substep: 2,
      };
    }

    return {
      step: 2,
      substep: 1,
    };
  }
}
