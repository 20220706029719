import { v4 } from 'uuid';
import TYPES from '@/types';

// Application
import { GetIdentityVerificationStatusQuery }
  from '@/modules/onboarding/identity-verification/application/queries';
import GetInternetStatusQuery
  from '@/modules/internet-status/application/queries/get-internet-status-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/di/inject';

export default class VerifyIdentityPageViewModel {
  @Inject(TYPES.GET_IDENTITY_VERIFICATION_STATUS_QUERY)
  private readonly get_identity_verification_status_query!: GetIdentityVerificationStatusQuery;

  @Inject(TYPES.GET_INTERNET_STATUS_QUERY)
  private readonly get_internet_status_query!: GetInternetStatusQuery;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  private current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  readonly step_ids = {
    upload_documents: v4(),
  };

  is_loading = false;

  verification_status = 'pending';

  get last_event_data() {
    return this.get_identity_verification_status_query.execute();
  }

  get identify_verification_succeed() {
    return (
      (
        this.last_event_data.success
        && this.last_event_data.percent === 100
      )
      || this.current_step.payload.status === 'verified'
    );
  }

  get can_continue() {
    return (
      this.identify_verification_succeed
      && !this.is_loading
      && this.get_internet_status_query.execute()
    );
  }

  updateVerificationStatus = () => {
    if (this.last_event_data.percent === 100) {
      this.verification_status = this.last_event_data.status;
      return;
    }

    if (this.current_step.payload.status) {
      this.verification_status = this.current_step.payload.status;
      return;
    }

    if (!this.last_event_data.success) {
      this.verification_status = 'has_error';
    }
  }

  setCurrentStep = async () => {
    this.current_step = await this.get_on_boarding_step_query.execute('identity_verification');
  }
}
