






















import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'ErrorManualReview' })
export default class ErrorManualReview extends Vue {
  tryAgain() {
    this.$emit('tryAgain');
  }
}
