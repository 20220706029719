
































































































































import { Component, Vue } from 'vue-property-decorator';
import PersonaInfoPage2ViewModel from '@/vue-app/view-models/components/on-boarding/personal-info/persona-info-page-2-view-model';
import UploadFileCard from '@/vue-app/components/onboarding/documents/UploadFileCard.vue';
import { v4 } from 'uuid';
import { VueNotifier } from '@/modules/shared/infrastructure/notifiers/message_notifier';
import { requiredRule, notNull } from '@/vue-app/utils/form-rules';
import Card from '../Card.vue';
import AddressForm from './AddressForm.vue';
import RfcSection from './tax-information/rfc-section.vue';
import NationalitiesSection from './tax-information/nationalities-section.vue';
import TYPES from '@/types';

// Application
import CreateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import UpdateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import { GetPersonQueryService } from '@/modules/onboarding/person/application/queries';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/dependency_injection/inject';

@Component({
  name: 'PersonalInfoPage2',
  components: {
    PreviewDocument: () => import('@/vue-app/components/documents/preview-document.vue'),
    UploadFileCard,
    Card,
    AddressForm,
    RfcSection,
    NationalitiesSection,
  },
})
export default class PersonalInfoPage2 extends Vue {
  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  readonly createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  readonly updateOnboardingStepCommand!: UpdateOnboardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly vueNotifier!: VueNotifier;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.GET_PERSON_QUERY_SERVICE)
  private readonly get_person_query!: GetPersonQueryService;

  personal_info_page2_view_model = Vue.observable(new PersonaInfoPage2ViewModel());

  $refs!: {
    address_form: Vue;
    rfc_section: Vue;
    nationalities_section: Vue;
    proof_of_address_input: UploadFileCard;
  };

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  personal_information_step: StepEntity = {
    current_step: '',
    payload: {},
    id: '',
  };

  step_ids = {
    financial_information: v4(),
  };

  inputs = {
    usa_fiscal_responsibility: false,
    tax_id: '',
  };

  verify_fiscal_address = true;

  rules = {
    required: [requiredRule],
    not_null: [notNull],
  };

  async selectFiscalProofOfAddress(base64: string, mime_type: string, file_name: string) {
    try {
      await this.personal_info_page2_view_model.selectFiscalProofOfAddress(
        base64, mime_type, file_name,
      );
    } catch {
      this.$refs.proof_of_address_input.upload_file_card_view_model.resetStatus(
        this.$refs.proof_of_address_input.initial_file,
      );
      this.$refs.proof_of_address_input.upload_file_card_view_model.showSelectError(
        this.$refs.proof_of_address_input.error_message,
      );
    }
  }

  async updateStepData() {
    let fiscal_address;
    const person = this.get_person_query.execute();

    if (!person.id) {
      const event = new CustomEvent('executor_link', {
        detail: {
          result: Promise.reject(new Error('Invalid person id')),
        },
      });
      window.dispatchEvent(event);
      throw new Error('Invalid person id');
    }

    if (this.verify_fiscal_address) {
      const personal_address = this.personal_information_step.payload.addresses[0];
      const id = v4();

      fiscal_address = {
        ...personal_address,
        customer_address_default: false,
        customer_address_id: id,
        address: {
          ...personal_address.address,
          id,
          address_type: 'fiscal',
        },
      };
    } else {
      fiscal_address = this.$refs.address_form.$data.address_form_view_model.inputs;
    }
    await this.updateOnboardingStepCommand.execute({
      id: this.current_step.id,
      current_step: this.current_step.current_step,
      payload: {
        personal_information: {
          ...this.inputs,
          ...this.$refs.nationalities_section.$data.nationalities_section_view_model.inputs,
        },
        addresses: [fiscal_address],
        person: {
          id: person.id,
          rfc: this.$refs.rfc_section.$data.rfc_section_view_model.rfc,
          fiel: this.$refs.rfc_section.$data.rfc_section_view_model.inputs.fiel,
        },
        agreements: [
          ...this.$refs.rfc_section.$data.rfc_section_view_model.customer_agreements,
        ],
      },
    });
    await this.createOnboardingStepCommand.execute({
      id: this.step_ids.financial_information,
      current_step: 'financial_information',
      payload: {
        financial_information: {
          expected_transactionality_id: '',
          source_of_resources: null,
          annual_income: null,
          activity_or_line_of_business: '',
          activity_or_line_of_business_vulneravility: null,
          activity_or_line_of_business_high_flow: null,
          profession: '',
          company_where_work: '',
          has_issuance_of_securities: null,
          job: '',
          admission_job_year: '',
          estimated_monthly_deposits: '',
          estimated_monthly_withdrawals: '',
          estimated_monthly_amount_deposits: '',
          estimated_monthly_amount_withdrawals: '',
          initial_investment: 0,
          maximum_expected_investment: 0,
          confirm_no_politically_exposed_person: false,
          confirm_resources_provider: false,
          confirm_resource_owner: false,
          confirm_acting_on_its_own: false,
        },
        agreements: [],
      },
    });
  }

  async created() {
    window.scrollTo(0, 0);
    await this.personal_info_page2_view_model.initialize();

    this.get_person_query.execute();
    this.current_step = await this.get_on_boarding_step_query.execute('tax_information');
    this.inputs = {
      tax_id: this.current_step.payload.personal_information.tax_id,
      usa_fiscal_responsibility:
        this.current_step.payload.personal_information.usa_fiscal_responsibility,
    };
    this.verify_fiscal_address = this.current_step.payload.personal_information
      .verify_fiscal_address;
    this.personal_information_step = await this.get_on_boarding_step_query.execute(
      'personal_information',
    );
  }
}
