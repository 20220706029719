/* eslint-disable @typescript-eslint/no-non-null-assertion */

import Vue from 'vue';
import { v4 } from 'uuid';
import { requiredRule, RuleResponseType } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import OnBoardingSearchCustomerEmailsQuery from '@/modules/onboarding/customer-email/application/queries/on-boarding-search-customer-emails-query';
import GetInternetStatusQuery from '@/modules/internet-status/application/queries/get-internet-status-query';
import UpdateOnboardingStepCommand from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';
import { VerifyNipCommandService } from '@/modules/verify-nip/application/commands';
import { RenewVerifyNipCommandService } from '@/modules/renew-verify-nip/application/commands';
import CreateOnboardingStepCommand from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import GetPermissionsStatusService from '@/modules/permissions/status/application/services/get-permissions-status-service';
import SearchGeolocationReferencesQuery from '@/modules/geolocation/application/queries/search-geolocation-references-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import GeolocationService from '@/modules/geolocation/application/services/geolocation-service';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import { Geolocation } from '@/modules/shared/domain/geolocation';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/dependency_injection/inject';

export class NipPageViewModel {
  @Inject(TYPES.ON_BOARDING_SEARCH_CUSTOMER_EMAILS_QUERY)
  readonly onboardingListEmailsQuery!: OnBoardingSearchCustomerEmailsQuery;

  @Inject(TYPES.VERIFY_NIP_COMMAND_SERVICE)
  readonly verifyNipCommandService!: VerifyNipCommandService;

  @Inject(TYPES.RENEW_VERIFY_NIP_COMMAND_SERVICE)
  readonly resendVerifyNipCommandService!: RenewVerifyNipCommandService;

  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  readonly createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly vueNotifier!: MessageNotifier;

  @Inject(TYPES.GET_INTERNET_STATUS_QUERY)
  readonly getInternetStatusQuery!: GetInternetStatusQuery;

  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnboardingStepCommand;

  @Inject(TYPES.GET_PERMISSIONS_STATUS_SERVICE)
  readonly getPermissionStatusService!: GetPermissionsStatusService;

  @Inject(TYPES.GEOLOCATION)
  readonly geolocation!: Geolocation;

  @Inject(TYPES.SEARCH_GEOLOCATION_REFERENCES_QUERY)
  readonly searchGeolocationReferencesQuery!: SearchGeolocationReferencesQuery;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.GEOLOCATION_SERVICE)
  readonly geolocation_service!: GeolocationService;

  is_loading = false;

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  step_ids = {
    identity_verification: v4(),
  };

  geolocation_loaded = false;

  inputs = {
    nip: '',
  };

  nip: Record<string, any> = {
    valid: true,
    timeoutId: null,
    expirationTimeInMilliseconds: 120000,
  };

  valid_form = false;

  rules = {
    nip_rules: [
      requiredRule,
      (value: string): RuleResponseType => value.length === 7 || this.view.$t('shared.form-rules.nip_format').toString(),
    ],
  };

  show_error = false;

  show_resend_nip_option = false;

  title_error = '';

  text_error = '';

  view!: Vue;

  is_nip_field_full = false;

  constructor(view: Vue) {
    this.view = view;
  }

  get internet_connection() {
    return this.getInternetStatusQuery.execute();
  }

  get geolocation_permission_granted() {
    return this.geolocation_service.getGeolocationStatus();
  }

  get can_continue() {
    return (
      this.valid_form
      && !this.is_loading
      && this.internet_connection
      && this.is_nip_field_full
      && this.nip.valid
    );
  }

  get email() {
    return this.onboardingListEmailsQuery.execute().find(
      (email) => email.customer_email_default,
    ) || { email: { email_address: 'con el que ingresaste' } };
  }

  onFinish = () => {
    this.is_nip_field_full = true;
  };

  handleNext = async () => {
    this.is_loading = true;
    this.removeNipTimeout();
    try {
      const post_data = { ...this.inputs };
      post_data.nip = post_data.nip.replace('-', '');
      await this.verifyNipCommandService.execute(post_data);
      await this.createOnboardingStepCommand.execute({
        id: this.step_ids.identity_verification,
        current_step: 'identity_verification',
        payload: {
          attempt: 1,
          status: 'pending',
        },
      });
      this.view.$emit('handleNext');
    } catch (error) {
      this.show_error = true;
      this.title_error = 'Error en la verificación del código';
      this.text_error = 'Ha ocurrido un error en la verificación del código. Por favor, inténtelo nuevamente';
      this.show_resend_nip_option = true;
    } finally {
      this.is_loading = false;
    }
  };

  resendNip = async () => {
    try {
      await this.resendVerifyNipCommandService.execute({
        id: v4(),
      });
      this.vueNotifier.showInfoNotification(
        'El reenvío de código se realizó correctamente. Por favor, revise su correo.',
      );
    } catch (e) {
      this.show_error = true;
      this.title_error = 'No fue posible el reenvío del nip de verificación';
      this.text_error = 'Ha ocurrido un error al reenviar el nip de verificación, inténtelo nuevamente';
    }
  };

  handlePermissionChange = (new_permission_status: boolean) => {
    if (new_permission_status) {
      if (!this.current_step?.payload.geolocation.id) {
        this.searchGeolocationReferencesQuery.execute()
          .then((items) => {
            const on_boarding_geolocation_reference = items.find(
              (item) => item.name === 'on_boarding_register',
            );

            this.geolocation.getCurrentPosition()
              .then((coordinates) => {
                this.current_step = {
                  ...this.current_step!,
                  payload: {
                    ...this.current_step!.payload,
                    geolocation: {
                      id: v4(),
                      reference: on_boarding_geolocation_reference,
                      coordinates: {
                        latitude: coordinates.latitude.toString(),
                        longitude: coordinates.longitude.toString(),
                      },
                    },
                  },
                };
                this.updateOnBoardingStepCommand.execute(
                  this.current_step,
                );
                this.geolocation_loaded = true;
              });
          });
      } else {
        this.geolocation_loaded = true;
      }
    }
  };

  setNipTimeout = () => {
    this.nip.timeoutId = setTimeout(
      this.showInvalidNipMessage,
      this.nip.expirationTimeInMilliseconds,
    );
  };

  removeNipTimeout = () => {
    clearTimeout(this.nip.timeoutId);
  }

  showInvalidNipMessage = () => {
    this.nip.valid = false;
    this.removeNipTimeout();
  }

  initialize = async () => {
    window.scrollTo(0, 0);
    this.current_step = await this.get_on_boarding_step_query.execute('verify_nip');
    this.setNipTimeout();
  };
}
