import { requiredRule } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Infrastructure
import PersonPresenter
  from '@/modules/onboarding/person/infrastructure/presenters/person-presenter';

// Application
import { GetPersonQueryService } from '@/modules/onboarding/person/application/queries';
import SearchAgreementsQuery
  from '@/modules/agreements/application/queries/search-agreements-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import GetMaritalStatusesQuery from '@/modules/onboarding/catalogs/marital-status/application/queries/get-marital-statuses-query';

// Domain
import { MaritalStatusEntity } from '@/modules/onboarding/catalogs/marital-status/domain/entities/marital-status-entity';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class PersonalInfoViewModel {
  @Inject(TYPES.GET_PERSON_QUERY_SERVICE)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_AGREEMENTS_QUERY)
  private readonly search_agreements_query!: SearchAgreementsQuery;

  @Inject(TYPES.GET_MARITAL_STATUSES_QUERY)
  readonly get_marital_statuses_query!: GetMaritalStatusesQuery;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly notifier!: MessageNotifier;

  rules = {
    required: [requiredRule],
  };

  person_presenter?: PersonPresenter;

  is_loading = true;

  selected_marital_status_id = '';

  get person() {
    return this.get_person_query.execute();
  }

  get agreements() {
    return this.search_agreements_query.execute();
  }

  get current_step() {
    return this.get_on_boarding_step_query.execute('personal_information');
  }

  get marital_statuses(): Array<MaritalStatusEntity> {
    return this.get_marital_statuses_query.execute();
  }

  public constructor() {
    this.get_person_query.execute();
  }

  createPersonPresenter = () => {
    this.person_presenter = new PersonPresenter(this.person);
  }
}
