import TYPES from '@/types';

// APPLICATION
import SearchPromotorQuery from '@/modules/onboarding/catalogs/promotor/application/queries/search-promotor-query';

// DOMAIN
import { PromotorEntity } from '@/modules/onboarding/catalogs/promotor/domain/entities/promotor-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class ColumbusAdvisorViewModel {
  @Inject(TYPES.SEARCH_PROMOTOR_QUERY)
  readonly search_promotor_query!: SearchPromotorQuery;

  @Inject(TYPES.NOTIFIER)
  readonly notifier!: MessageNotifier;

  has_columbus_advisor = false;

  loading = false;

  promotor_items: Array<PromotorEntity> = [];

  inputs = {
    search: '',
    cve_promotor: '',
  };

  getPromotorFullName = (item: PromotorEntity) => `${item.NOMBRES} ${item.APATERNO} ${item.AMATERNO}`;

  searchPromotor = async () => {
    if (this.inputs.search.length <= 3) return;

    this.loading = true;
    try {
      this.promotor_items = await this.search_promotor_query.execute(this.inputs.search);
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al buscar el Asesor Columbus');
    } finally {
      this.loading = false;
    }
  };
}
