import { v4 } from 'uuid';
import { requiredRule } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import SearchAgreementsQuery
  from '@/modules/agreements/application/queries/search-agreements-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { CustomerAgreementEntity }
  from '@/modules/onboarding/status/domain/entities/customer-agreement-entity';
import { AgreementEntity }
  from '@/modules/agreements/domain/entities/agreement-entity';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import Inject from '@/modules/shared/domain/di/inject';

export default class DisclaimersViewModel {
  @Inject(TYPES.SEARCH_AGREEMENTS_QUERY)
  readonly searchAgreementsQuery!: SearchAgreementsQuery;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  open = {
    confirm_no_politically_exposed_person: false,
    confirm_resources_provider: false,
    confirm_resource_owner: false,
  };

  inputs: any = {
    confirm_no_politically_exposed_person: false,
    confirm_resources_provider: false,
    confirm_resource_owner: false,
    confirm_acting_on_its_own: false,
  };

  rules = {
    required: [requiredRule],
  };

  user_agreements: Array<CustomerAgreementEntity> = [];

  agreements: Array<AgreementEntity> = [];

  get confirmNoPoliticallyExposed() {
    return this.inputs.confirm_no_politically_exposed_person;
  }

  get confirmResourcesProvider() {
    return this.inputs.confirm_resources_provider;
  }

  get confirmResourceOwner() {
    return this.inputs.confirm_resource_owner;
  }

  get confirm_acting_on_its_own() {
    return this.inputs.confirm_acting_on_its_own;
  }

  handleConfirmNoPoliticallyExposedPerson = (confirm: boolean) => {
    this.open.confirm_no_politically_exposed_person = false;
    this.inputs.confirm_no_politically_exposed_person = confirm;
  }

  handleConfirmRealResourceProvider = (confirm: boolean) => {
    this.open.confirm_resources_provider = false;
    this.inputs.confirm_resources_provider = confirm;
  };

  handleConfirmRealOwnerOfResources = (confirm: boolean) => {
    this.open.confirm_resource_owner = false;
    this.inputs.confirm_resource_owner = confirm;
  };

  handleAgreementChange = (agreement_name: string, confirmed: boolean) => {
    const agreement = this.agreements.find(
      (item) => item.name === agreement_name,
    );

    if (agreement) {
      const user_agreement_index = this.user_agreements.findIndex(
        (item) => item.agreement_type_id === agreement.id,
      );

      if (user_agreement_index >= 0) {
        this.user_agreements[user_agreement_index].accepted_on = confirmed
          ? this.datetimeValue.create()
          : null;
        this.user_agreements[user_agreement_index].was_accepted = confirmed;
      }
    }
  };

  createAgreement = async (agreement_name: string) => {
    const agreement = this.agreements.find(
      (item) => item.name === agreement_name,
    );
    if (agreement) {
      this.user_agreements.push({
        id: v4(),
        agreement_type_id: agreement.id,
        accepted_on: null,
        description: agreement.description,
        was_accepted: false,
      });
    }
  };

  initStepData = async () => {
    this.current_step = await this.get_on_boarding_step_query.execute('financial_information');
    this.agreements = this.searchAgreementsQuery.execute();

    if (this.current_step.payload.agreements.length === 0) {
      await this.createAgreement('not_politically_exposed_person');
      await this.createAgreement('resource_provider');
      await this.createAgreement('resource_owner');
      await this.createAgreement('acting_on_its_own');
    } else {
      this.user_agreements = this.current_step.payload.agreements;
    }
  };

  initialize = async () => {
    await this.initStepData();
    this.inputs = {
      confirm_no_politically_exposed_person:
        this.current_step.payload.financial_information.confirm_no_politically_exposed_person,
      confirm_resources_provider:
        this.current_step.payload.financial_information.confirm_resources_provider,
      confirm_resource_owner:
        this.current_step.payload.financial_information.confirm_resource_owner,
      confirm_acting_on_its_own:
        this.current_step.payload.financial_information.confirm_acting_on_its_own,
    };
  };
}
