























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { v4 } from 'uuid';
import { requiredRule, notNull } from '@/vue-app/utils/form-rules';
import Card from './Card.vue';
import TYPES from '@/types';

// Infrastructure
import { VueNotifier } from '@/modules/shared/infrastructure/notifiers/message_notifier';

// Application
import CreateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import UpdateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';
import CreateOnBoardingCommand
  from '@/modules/onboarding/on-boarding/application/commands/create-on-boarding-command';
import SearchAgreementsAsyncQuery
  from '@/modules/agreements/application/queries/search-agreements-async-query';
import GetInternetStatusQuery
  from '@/modules/internet-status/application/queries/get-internet-status-query';
import CompleteOnBoardingService
  from '@/modules/onboarding/status/application/services/complete-on-boarding';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { CustomerAgreementEntity }
  from '@/modules/onboarding/status/domain/entities/customer-agreement-entity';
import { AgreementEntity }
  from '@/modules/agreements/domain/entities/agreement-entity';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import Inject from '@/modules/shared/domain/di/inject';

@Component({
  name: 'TermsAndConditionsPage',
  components: { Card },
})
export default class TermsAndConditionsPage extends Vue {
  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  readonly createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  readonly updateOnboardingStepCommand!: UpdateOnboardingStepCommand;

  @Inject(TYPES.SEARCH_AGREEMENTS_ASYNC_QUERY)
  readonly searchAgreementsAsyncQuery!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.GET_INTERNET_STATUS_QUERY)
  readonly getInternetStatusQuery!: GetInternetStatusQuery;

  @Inject(TYPES.COMPLETE_ONBOARDING_SERVICE)
  readonly completeOnBoardingService!: CompleteOnBoardingService;

  @Inject(TYPES.NOTIFIER)
  readonly vueNotifier!: VueNotifier;

  @Inject(TYPES.CREATE_ON_BOARDING_COMMAND)
  readonly createOnBoardingCommand!: CreateOnBoardingCommand;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  step_ids = {
    validation_process: v4(),
  };

  inputs: any = {
    confirm_terms_and_conditions: null,
    confirm_privacy_notice: null,
  }

  is_loading = false;

  rules = {
    required: [requiredRule],
    not_null: [notNull],
  }

  user_agreements: Array<CustomerAgreementEntity> = [];

  agreements: Array<AgreementEntity> = [];

  get confirmTermsAndConditions() {
    return this.inputs.confirm_terms_and_conditions;
  }

  get confirmPrivacyNotice() {
    return this.inputs.confirm_privacy_notice;
  }

  get canGoBack() {
    return this.getInternetStatusQuery.execute();
  }

  get canContinue() {
    return (
      !Object.values(this.inputs).some((input) => !input)
      && this.getInternetStatusQuery.execute()
    );
  }

  async handleNext() {
    try {
      await this.$store.dispatch('app/togglePreventExit', true);
      this.is_loading = true;
      await this.updateOnboardingStepCommand.execute({
        current_step: this.current_step.current_step,
        id: this.current_step.id,
        payload: {
          ...this.inputs,
          agreements: this.user_agreements,
          errors: this.current_step.payload.errors || {},
        },
      });
      await this.completeOnBoardingService.execute();
      await this.createOnBoardingCommand.execute({});
      await this.createOnboardingStepCommand.execute({
        current_step: 'validation_process',
        id: this.step_ids.validation_process,
        payload: {
          is_valid: true,
          rescheduled: false,
          errors: {
            sign_up: [],
            confirmation: [],
            beneficiaries: [],
            tax_information: [],
            bank_information: [],
            personal_information: [],
            financial_information: [],
          },
        },
      });
      this.$emit('handleNext');
    } finally {
      this.is_loading = false;
      await this.$store.dispatch('app/togglePreventExit', false);
    }
  }

  handleBack() {
    this.$emit('handleBack');
  }

  handleAgreementChange(agreement_name: string, confirmed: boolean) {
    const agreement = this.agreements.find(
      (item) => item.name === agreement_name,
    );

    if (agreement) {
      const user_agreement_index = this.user_agreements.findIndex(
        (item) => item.agreement_type_id === agreement.id,
      );

      if (user_agreement_index >= 0) {
        this.user_agreements[user_agreement_index].accepted_on = confirmed
          ? this.datetimeValue.create()
          : null;
        this.user_agreements[user_agreement_index].was_accepted = confirmed;
      }
    }
  }

  @Watch('confirmTermsAndConditions')
  handleConfirmTermsAndConditionsChange(new_confirmation: boolean) {
    this.handleAgreementChange('terms_and_conditions_allianz', new_confirmation);
  }

  @Watch('confirmPrivacyNotice')
  handleConfirmPrivacyNoticeChange(new_confirmation: boolean) {
    this.handleAgreementChange('notice_of_privacy_allianz', new_confirmation);
  }

  createAgreement(agreement_name: string) {
    const agreement = this.agreements.find(
      (item) => item.name === agreement_name,
    );
    if (agreement) {
      this.user_agreements.push({
        id: v4(),
        agreement_type_id: agreement.id,
        accepted_on: null,
        description: agreement.description,
        was_accepted: false,
      });
    }
  }

  async initStepData() {
    this.agreements = await this.searchAgreementsAsyncQuery.execute();
    this.current_step = await this.get_on_boarding_step_query.execute('confirmation');

    if (this.current_step.payload.agreements.length === 0) {
      this.createAgreement('terms_and_conditions_allianz');
      this.createAgreement('notice_of_privacy_allianz');
    } else {
      this.user_agreements = this.current_step.payload.agreements;
    }
  }

  async created() {
    window.scrollTo(0, 0);
    await this.initStepData();
    this.inputs = this.current_step.payload;
  }
}
