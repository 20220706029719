import Vue from 'vue';

export default class RealOwnerOfResourcesViewModel {
  readonly view!: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  handleConfirmRealOwnerOfResources = () => {
    this.view.$emit('confirmRealOwnerOfResources', true);
  };
}
