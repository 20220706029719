















































































import { Component, Vue } from 'vue-property-decorator';
import Card from '../Card.vue';

@Component({
  name: 'PrepareDocuments',
  components: { Card },
})
export default class PrepareDocuments extends Vue {}
