import Vue from 'vue';

export default class PoliticallyExposedPersonViewModel {
  readonly view!: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  handleConfirmNoPoliticallyExposedPerson = () => {
    this.view.$emit('confirmNoPoliticallyExposedPerson', true);
  };
}
