






















































import { Component, Vue } from 'vue-property-decorator';
import VerifyYourIdentityPageViewModel from '@/vue-app/view-models/components/on-boarding/verify-identity/verify-your-identity-page-view-model';

@Component({ name: 'VerifyYourIdentityPage' })
export default class VerifyYourIdentityPage extends Vue {
  verify_your_identity_page_view_model = Vue.observable(new VerifyYourIdentityPageViewModel(this));

  async created() {
    window.scrollTo(0, 0);
    await this.verify_your_identity_page_view_model.initialize();
  }
}
