




















import {
  Component, Vue, PropSync, Prop,
} from 'vue-property-decorator';

@Component({ name: 'CustomAutocomplete', components: { } })
export default class CustomAutocomplete extends Vue {
  @Prop(Array) items: Array<any> | undefined

  @Prop(Array) rules: Array<any> | undefined

  @Prop(String) value: string | undefined;

  @Prop(String) text: string | undefined;

  @Prop(Boolean) disabled: boolean | undefined;

  @PropSync('model')
  synced_model: string | undefined

  get is_disabled() {
    return this.disabled || false;
  }

  normalizeString(input_string: string) {
    let normalized = input_string.toLowerCase();
    try {
      normalized = normalized.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } catch (error) {
      console.error(error);
    }
    return normalized;
  }

  customSearchFilter(item: any, queryText: string, itemText: string): boolean {
    const itemName = this.normalizeString(itemText || '');
    const searchText = this.normalizeString(queryText || '');
    return itemName.indexOf(searchText) > -1;
  }
}
