














































import { Vue, Component } from 'vue-property-decorator';
import RegistrationStatusViewModel
  from '@/vue-app/view-models/components/on-boarding/registration-status-view-model';

@Component({
  name: 'RegistrationStatus',
  components: {
    ValidationProcess: () => import('@/vue-app/components/onboarding/registration-status/ValidationProcess.vue'),
    SignDocuments: () => import('@/vue-app/components/onboarding/registration-status/SignDocuments.vue'),
    InitialContribution: () => import('@/vue-app/components/onboarding/registration-status/InitialContribution.vue'),
  },
})
export default class RegistrationStatus extends Vue {
  registration_status_view_model = Vue.observable(new RegistrationStatusViewModel(this));

  async created() {
    await this.registration_status_view_model.initialize();
  }
}
