





























































































































































// TODO: Add catalog for relationships

import {
  Component, Vue, PropSync, Prop, Watch,
} from 'vue-property-decorator';

import Card from '../Card.vue';
import PhoneCountryCodeSelect from '@/vue-app/components/onboarding/personal-info/PhoneCountryCodeSelect.vue';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';
import { BeneficiariesFormViewModel } from '@/vue-app/view-models/components/on-boarding/beneficiaries/beneficiaries-form-view-model';

// DOMAIN
import { BeneficiaryEntity } from '@/modules/onboarding/beneficiary/domain/types';

@Component({
  name: 'BeneficiariesForm',
  components: { PhoneCountryCodeSelect, CustomAutocomplete, Card },
})
export default class BeneficiariesForm extends Vue {
  @PropSync('beneficiary')
  synced_beneficiary!: BeneficiaryEntity;

  @Prop(Number) index: number | undefined;

  beneficiaries_form_view_model = Vue.observable(new BeneficiariesFormViewModel(this));

  handleRemove() {
    this.$emit('handleRemove', this.index);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectPhoneContactCountryCode(country_code: string) {
    // TODO: Add country_code property to beneficiary or prepend code to number
  }

  created() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.beneficiaries_form_view_model.setFormIndex(this.index!);
    this.beneficiaries_form_view_model.setBeneficiary(this.synced_beneficiary);
  }

  @Watch('synced_beneficiary', { deep: true })
  setSyncedBeneficiary() {
    this.beneficiaries_form_view_model.setBeneficiary(this.synced_beneficiary);
  }
}
