import { requiredRule, notNull } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import GetNationalitiesQueryService
  from '@/modules/onboarding/catalogs/nationality/application/queries/get-nationalities-query-service';
import { GetResidencesQueryService }
  from '@/modules/onboarding/catalogs/residence/application/queries';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { NationalityEntity } from '@/modules/onboarding/catalogs/nationality/domain/entities/nationality-entity';
import { ResidenceEntity } from '@/modules/onboarding/catalogs/residence/domain/entities/residence-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/dependency_injection/inject';

export default class NationalitiesSectionViewModel {
  @Inject(TYPES.GET_NATIONALITIES_QUERY_SERVICE)
  readonly get_nationalities_query!: GetNationalitiesQueryService;

  @Inject(TYPES.GET_RESIDENCESE_QUERY_SERVICE)
  readonly get_residences_query!: GetResidencesQueryService;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly notifier!: MessageNotifier

  readonly inputs_config = {
    has_another_nationality: {
      rules: [notNull],
    },
    residence: {
      rules: [requiredRule],
    },
  };

  loading = false;

  initializing = false;

  inputs = {
    has_another_nationality: false,
    other_nationality: '',
    residence: '',
  };

  nationalities: Array<NationalityEntity> = [];

  residences: Array<ResidenceEntity> = [];

  get national_residence() {
    return this.residences.find(
      (item) => item.value === 'Nacional',
    )?.id;
  }

  get foreign_residence() {
    return this.residences.find(
      (item) => item.value === 'Extranjero',
    )?.id;
  }

  getNationalities = async () => {
    try {
      this.loading = true;
      this.nationalities = await this.get_nationalities_query.execute();
    } catch (e) {
      this.notifier.showErrorNotification('Ocurrió un error al obtener la información de nacionalidad y residencia.');
    } finally {
      this.loading = false;
    }
  };

  getResidences = async () => {
    try {
      this.loading = true;
      this.residences = await this.get_residences_query.execute();
    } catch (e) {
      this.notifier.showErrorNotification('Ocurrió un error al obtener la información de nacionalidad y residencia.');
    } finally {
      this.loading = false;
    }
  };

  get current_step() {
    return this.get_on_boarding_step_query.execute('tax_information');
  }

  initialize = async () => {
    this.initializing = true;
    await this.getResidences();
    await this.getNationalities();

    const current_step = await this.current_step;

    this.inputs = {
      has_another_nationality: current_step.payload.personal_information.has_another_nationality,
      other_nationality: current_step.payload.personal_information.other_nationality,
      residence: current_step.payload.personal_information.residence,
    };
    this.initializing = false;
  };
}
