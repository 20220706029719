


































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Card from '../Card.vue';
import UploadFileCard from './UploadFileCard.vue';
import PreviewDocument from '@/vue-app/components/documents/preview-document.vue';
import AcceptedProofOfAddress from '@/vue-app/components/onboarding/documents/AcceptedProofOfAddress.vue';
import { UploadDocumentsPageViewModel } from '@/vue-app/view-models/components/on-boarding/documents/upload-documents-page-view-model';

@Component({
  name: 'UploadDocumentsPage',
  components: {
    AcceptedProofOfAddress,
    Card,
    UploadFileCard,
    PreviewDocument,
  },
})
export default class UploadDocumentsPage extends Vue {
  upload_documents_view_model = Vue.observable(new UploadDocumentsPageViewModel());

  $refs!: {
    bank_statement_input: UploadFileCard;
    proof_of_address_input: UploadFileCard;
  };

  async selectBankStatement(base64: string, mime_type: string, file_name: string) {
    try {
      await this.upload_documents_view_model.selectBankStatement(base64, mime_type, file_name);
    } catch {
      this.$refs.bank_statement_input.upload_file_card_view_model.resetStatus(
        this.$refs.bank_statement_input.initial_file,
      );
      this.$refs.bank_statement_input.upload_file_card_view_model.showSelectError(
        this.$refs.bank_statement_input.error_message,
      );
    }
  }

  async selectProofOfAddress(base64: string, mime_type: string, file_name: string) {
    try {
      await this.upload_documents_view_model.selectProofOfAddress(base64, mime_type, file_name);
    } catch {
      this.$refs.proof_of_address_input.upload_file_card_view_model.resetStatus(
        this.$refs.proof_of_address_input.initial_file,
      );
      this.$refs.proof_of_address_input.upload_file_card_view_model.showSelectError(
        this.$refs.proof_of_address_input.error_message,
      );
    }
  }

  @Watch('upload_documents_view_model.can_continue', { deep: true })
  onCanContinueChange() {
    this.upload_documents_view_model.onCanContinueChange();
  }

  @Watch('upload_documents_view_model.loading', { deep: true })
  onLoadingChange() {
    this.upload_documents_view_model.onLoadingChange();
  }

  created() {
    window.scrollTo(0, 0);
    this.upload_documents_view_model.initialize();
  }
}
