

































import { Component, Vue, PropSync } from 'vue-property-decorator';
import RealResourceProviderViewModel from '@/vue-app/view-models/components/on-boarding/financial-information/real-resource-provider-view-model';

@Component({ name: 'RealResourceProvider' })
export default class RealResourceProvider extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  private readonly real_resource_provider_view_model = Vue.observable(
    new RealResourceProviderViewModel(this),
  );

  accept = null;
}
