import { StepEntity } from '../../../domain/entities';
import { StepDto } from '../../../domain/dtos';

export default class OnboardingStep {
  getStepAndSubStep(step: StepEntity): StepDto {
    try {
      if (step) {
        // eslint-disable-next-line
        const Handler = require(`./sections/${step.current_step.replaceAll('_', '-')}`).default;
        const handler = new Handler();

        return handler.getStepAndSubStep(step.payload);
      }
    // eslint-disable-next-line no-empty
    } catch {}

    return {
      step: 1,
      substep: 1,
    };
  }
}
