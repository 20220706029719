
































import { Vue, Component } from 'vue-property-decorator';
import TYPES from '@/types';

// Application
import { UpdateOnboardingStepCommand } from '@/modules/onboarding/status/application/commands';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/di/inject';

@Component({ name: 'ErrorVerifyIdentity' })
export default class ErrorVerifyIdentity extends Vue {
  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateOnboardingStepCommand;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  is_loading = false;

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  async tryAgain() {
    this.is_loading = true;
    await this.update_on_boarding_step_command.execute({
      id: this.current_step.id,
      current_step: this.current_step.current_step,
      payload: {
        attempt: this.current_step.payload.attempt ? this.current_step.payload.attempt + 1 : 2,
        status: 'pending',
      },
    });
    this.$emit('tryAgain');
    this.is_loading = false;
  }

  async created() {
    this.current_step = await this.get_on_boarding_step_query.execute('identity_verification');
  }
}
